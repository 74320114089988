import React from "react";
import styled from "styled-components";

import SubjectInfoCard from "./SubjectInfoCard;";
import SubjectFormativeModules from "./SubjectFormativeModules";

const SubjectBoard = (props) => {
  const { subject, totalProgress, origin, resetData } = props;

  return (
    <BoardWrapper>
      <SubjectInfoCard subject={subject} totalProgress={totalProgress} />
      <SubjectFormativeModules
        modules={subject?.courses}
        origin={origin}
        demo={subject?.type}
        resetData={resetData}
      />
    </BoardWrapper>
  );
};

export default SubjectBoard;

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  box-sizing: border-box;
  height: 100%;
  margin: 1rem 6rem;
  z-index: 25;

  @media (max-width: 1440px) {
    margin: 1rem 0;
  }

  @media (max-width: 768px) {
    margin: 0 0;
  }
`;
