import styled from "styled-components";
import ImgSuccess from "../../../../../assets/media/aden/ticket-success.png"

const PopUpSuccess = (props) => {

    const { setOpenModal } = props;

    return (
        <Modal>
            <img src={ImgSuccess} alt="success" />
            <h3>¡El anuncio se <br/> publicó exitosamente!</h3>
            <button onClick={() => setOpenModal(false)}>Volver</button>
        </Modal>
    )
}

export default PopUpSuccess;

const Modal = styled.div`
    position: fixed;
    width: 450px;
    height: 450px;
    top: 30rem;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 99;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    h3 {
        text-align: center;
    }

    button {
        cursor: pointer;
        width: 80%;
        height: 40px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background-color: #CD2118;

        :hover {
            background-color: #b31d15;
        }
    }
`