import { useState } from "react";
import styled from "styled-components";
import AnnouncementCard from "../../ui/organisms/subject/announcements/AnnouncementCard";
import { formatDate } from "../../../modules/acropolisCommon/utils/formatDate";
import { toTitleCase } from "../../../helpers/titleCase.helper"

const AnnouncementCardNotification = (props) => {

    const { subjectName, announcement, index } = props;

    const [openModal, setOpenModal] = useState(false);

    const today = new Date()

    const announcementIsNew = today >= new Date(announcement?.published_date) && new Date(announcement?.expiration_date) >= today; 

    // Revisar todos los campos que trae el anuncio y ponerlos bien

    return (
        <>
            <WrapperNotification
                new={announcementIsNew}
                launch_url={announcement?.published_date}
                key={index}
            >
                <SubjectMsg
                    new={announcementIsNew}
                >
                    ASIGNATURA: {subjectName}
                </SubjectMsg>
                <Line />
                <TitleWrapper>
                    <TitleMsg
                        new={announcementIsNew}
                    >
                        {announcement?.title}
                    </TitleMsg>
                    <Hour>{formatDate(announcement?.published_date, "DD/MM/YY")}</Hour>
                </TitleWrapper>
                <ProfessorWrapper>
                    <label>{announcement?.newsletter_creator?.name}</label>
                    <label>|</label>
                    <label>{toTitleCase(announcement?.newsletter_creator?.newsletter_creator_role)}</label>
                </ProfessorWrapper>
                <Descriptions
                    new={announcementIsNew}
                >
                    <Msg>{announcement?.message}</Msg>
                    <button onClick={() => setOpenModal(true)}>Ver anuncio completo.</button>
                </Descriptions>
            </WrapperNotification>
            {
                openModal &&
                <ModalContainer>
                    <ModalWindow>
                        <AnnouncementCard subjectName={subjectName} card={announcement} isOpen={openModal} setOpenModal={setOpenModal} />
                        <CloseModalButton onClick={() => setOpenModal(false)}>Volver</CloseModalButton>
                    </ModalWindow>
                </ModalContainer>
            }
        </>
    )
}

export default AnnouncementCardNotification;

const WrapperNotification = styled.button`
    width: 100%;
    max-width: 330px;
    min-height: 160px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    border: 1px solid #f1f1f1;
    box-shadow: ${(p) =>
            !p.new
                ? "none"
                : "0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)"};
    border-radius: 20px;
    background-color: ${(p) =>
        !p.new ? "#F9F9F9" : "#fff"};
    margin: 0.5rem auto;
    padding: 1rem;
    position: relative;
    overflow: hidden;

    ::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        height: 100%;
        width: 9px;
        background-color: ${(p) => (!p.new ? "#d9d9d9" : "#b31d15")};
    }
`;

const SubjectMsg = styled.h6`
    color: ${(p) => !p.new ? "#616161" : "#222222"};
    font-size: 11px;
    font-weight: 400;
    max-width: 100%;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #222;
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const TitleMsg = styled.h6`
    color: ${(p) => !p.new ? "#616161" : "#222222"};
    font-size: 0.8rem;
    font-weight: bold;
    max-width: 190px;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Msg = styled.h6`
    color: #616161;
    font-size: 0.8rem;
    text-align: start;
    white-space: wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    font-weight: 400;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const ProfessorWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    gap: 4px;

    label {
        font-size: 12px;
        font-weight: 700;
        color: #222;
    }
`

const Descriptions = styled.div`
    height: 100%;
    width: 18rem;
    display: flex;
    flex-direction: column;
    align-items: start;

    button {
        cursor: pointer;
        color: ${(props) => !props.new ? "#616161" : "#b31d15"};
        font-size: 0.8rem;
        font-weight: 700;
    }
`;

const Hour = styled.div`
    color: "#222222";
    font-size: 12px;
    font-weight: bold;
`;

const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100vw - 6rem);
    height: 100vh;
    background-color: #00000030;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
`

const ModalWindow = styled.div`
    width: 100%;
    max-width: 70vw;
    height: fit-content;
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 2rem;
    border-radius: 20px;
`

const CloseModalButton = styled.button`
    cursor: pointer;
    width: 88px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #b31d15;
`