import React from 'react'
import styled, { keyframes } from 'styled-components'

const SkeletonPlan = () => {
    return (
        <Container>
            {/* <TabsSkeleton /> */}
            <BannerSkeleton/>
            <FiltersSkeleton>
                <SearchSkeleton />
                <TabsContainer>
                    <Tab>
                        TODOS
                    </Tab>
                    <Tab>
                        EN CURSO
                    </Tab>
                    <Tab>
                        PENDIENTE
                    </Tab>
                    <Tab>
                        EGRESADO
                    </Tab>
                </TabsContainer>
            </FiltersSkeleton>
            <BodySkeleton>
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                {window.innerWidth > 1600 ? <CardSkeleton /> : null}
            </BodySkeleton>
        </Container>
    )
}

export default SkeletonPlan;

const twinkle = keyframes`
    0%{
        background-color: #e1e1e1;
    }
    50%{
        background-color: #cccccc;
    }
    100%{
        background-color: #e1e1e1;
    }
`;

const Container = styled.div`
    height: 100%;
    display: flex;
    gap: 32px;
    flex-direction: column;
    position: relative;
`

const BannerSkeleton = styled.div`
    border-radius: 20px;
    height: 280px;
    width: calc(100% - 4rem);
    animation: ${twinkle} 2s linear infinite;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5rem 2rem 0 2rem;
`

const FiltersSkeleton = styled.div`
    height: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-left: 2rem;
`

const SearchSkeleton = styled.div`
    height: 44px;
    width: 258px;
    animation: ${twinkle} 2s linear infinite;
    border-radius: 4px;
`

const TabsContainer = styled.div`
    display: flex;
    gap: 0.5rem;
`

const Tab = styled.div`
    height: 36px;
    padding: 0 16px;
    font-size: 1rem;
    animation: ${twinkle} 2s linear infinite;
    border-radius: 5px;
    color: transparent;
`

const BodySkeleton = styled.div`
    border-radius: 20px;
    height: 650px;
    width: calc(100% - 4rem);
    margin-left: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
`

const CardSkeleton = styled.div`
    height: 325px;
    width: 100%;
    border-radius: 20px;
    animation: ${twinkle} 2s linear infinite;
`