import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { decryptData, decryptID } from "../../../../helpers/encryptor.helper";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import * as base64 from "nodejs-base64-encode";

// FETCH AND STATE
import { setNewPassword, sendRecoverEmail } from "../../../../redux/api/auth";
import { authActions } from "../../../../redux/actions";

// COMPONENTS
import styled from "styled-components";
import Text from "../../../common/Text";
import { Form, Formik } from "formik";
import DefaultField from "../../../common/DefaultField";
import {
  recoverEmailSchema,
  recoverPasswordSchema,
} from "../../../../utils/schemas";
import { Button } from "@mui/material";
import { TaskAlt } from "@mui/icons-material";
import SimpleLoading from "../../../common/SimpleLoading";
import GenericSnackbar from "../../../common/GenericSnackbar";

const RecoverForm = (props) => {
  const { handleTab } = props;

  const initialValues = {
    email: "",
    password: "",
    confirmedPassword: "",
  };

  // REDUX
  const history = useHistory();
  const { recover_id } = useParams();
  const params = useQueryParams();
  const loginId = base64?.decode(
    params.size !== 0 ? params?.get("user") : "",
    "base64"
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [snackbarContent, setSnackbarContent] = useState({
    message: "",
    severity: "success",
  });

  const handleOpenSnackbar = (message, severity) => {
    setSnackbarContent({
      message: message,
      severity: severity,
    });
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleRedirectionToLogin = () => {
    handleTab(0);
    history.push("/auth");
  };

  const handleSubmitEmail = async (values) => {
    setIsLoading(true);
    try {
      const response = await sendRecoverEmail(values.email);
      if (response.data.status_code === 200) {
        handleOpenSnackbar("¡El correo se envió con éxito!", "success");
        setIsLoading(false);
        setStatus(true);
        return;
      }
    } catch (error) {
      handleOpenSnackbar("¡No se encontró el usuario!", "warning");
      setIsLoading(false);
    }
  };

  const handleSubmitNewPassword = async (values) => {
    setIsLoading(true);
    if (values.password !== values.confirmedPassword) {
      handleOpenSnackbar("¡Las contraseñas no coinciden!", "warning");
      return;
    }
    const request = await setNewPassword(loginId, values.password, recover_id);

    if (request.status === 200) {
      handleOpenSnackbar("¡La contraseña se cambió con éxito!", "success");
      setIsLoading(false);
      setStatus(true);
      setTimeout(() => {
        handleRedirectionToLogin();
      }, 1000);
      return;
    }
  };

  return (
    <Wrapper>
      <Content>
        <Text fontWeight="900" fontSize="1.5rem" color="#B31D15">
          Reestablecimiento de contraseña
        </Text>
        <Text fontWeight="500" textColor="#999999" fontSize="1rem">
          {!!recover_id
            ? "Ingresa tu nueva contraseña"
            : "Ingresa tu correo electrónico y verificaremos tu identidad."}
        </Text>
      </Content>
      {!recover_id ? (
        isLoading ? (
          <SimpleLoading />
        ) : !status ? (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitEmail}
            validationSchema={recoverEmailSchema}
          >
            {() => (
              <FormContainer>
                <TextFieldFake
                  name="email"
                  type="email"
                  placeholder="Ingresa tu correo electrónico"
                  required
                />
                <ButtonsWrapper>
                  <Button variant="contained" type="submit">
                    Enviar
                  </Button>
                  <Button onClick={() => handleTab(0)}>Volver al inicio</Button>
                </ButtonsWrapper>
              </FormContainer>
            )}
          </Formik>
        ) : (
          <>
            <Text fontWeight="500" textColor="#999999" fontSize="1rem">
              Te hemos enviado un correo, revisa tu buzón de entrada!
            </Text>
            <ButtonsWrapper>
              <Button onClick={() => handleTab(0)}>Volver al inicio</Button>
            </ButtonsWrapper>
          </>
        )
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitNewPassword}
            validationSchema={recoverPasswordSchema}
          >
            {() =>
              isLoading ? (
                <SimpleLoading />
              ) : !status ? (
                <FormContainer>
                  <InputsWrapper>
                    <TextFieldFake
                      name="password"
                      type="password"
                      placeholder="Ingresa tu nueva contraseña"
                      required
                    />
                    <TextFieldFake
                      name="confirmedPassword"
                      type="password"
                      placeholder="Confirma tu contraseña"
                      required
                    />
                  </InputsWrapper>
                  <ButtonsWrapper>
                    <Button variant="contained" type="submit">
                      Confirmar
                    </Button>
                    <Button onClick={() => handleRedirectionToLogin()}>
                      Volver al inicio
                    </Button>
                  </ButtonsWrapper>
                </FormContainer>
              ) : (
                <FakeTaskAlt />
              )
            }
          </Formik>
        </>
      )}
      <GenericSnackbar
        open={openSnackbar}
        message={snackbarContent.message}
        severity={snackbarContent.severity}
        handleClose={handleCloseSnackbar}
      />
    </Wrapper>
  );
};

export default RecoverForm;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2.3rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 400px;
`;

const FormContainer = styled(Form)`
  margin-top: 16rem 0 0 0;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
`;

const TextFieldFake = styled(DefaultField)`
  max-width: 100%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const FakeTaskAlt = styled(TaskAlt)`
  color: #2ce43b;
`;
