import { useRef, useEffect } from 'react';

import styled from 'styled-components';

import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.css';

import { Link } from 'react-router-dom';

import { Button, IconButton } from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { byPrefixAndName } from '@awesome.me/kit-81876cd84f';

const breakpoints = {
    860: { perView: 1 },
    1200: { perView: 2 },
    1600: { perView: 3 },
    2200: { perView: 4 },
};

const Slider = ({
    array,
    card: Card,
    to,
    config = {
        type: 'carousel',
        startAt: 0,
        perView: 4,
        gap: 32,
        breakpoints,
    },
    element = 'glide',
}) => {
    const sliderRef = useRef(new Glide(`.${element}`, config));

    useEffect(() => {
        mountSlider();
        return () => destroySlider();
    }, [sliderRef?.current]);

    const mountSlider = () => {
        try {
            sliderRef?.current?.mount();
        } catch (error) {
            console.log('faliled when mounting slider:', error);
            destroySlider();
        }
    };

    const destroySlider = () => {
        try {
            sliderRef?.current?.destroy();
        } catch (error) {
            console.log('faliled when destroying slider:', error);
        }
    };

    return (
        <Wrapper className={`${element}`}>
            <SliderWrapper className="glide__track" data-glide-el="track">
                <List className="glide__slides">
                    {array.map((item, index) => (
                        <Item key={index} className="glide__slide">
                            <Card
                                handleOpenModal={() => {}}
                                value={item}
                                index={index}
                            />
                        </Item>
                    ))}
                </List>
            </SliderWrapper>
            <ControlsWrapper>
                <Link to={to}>
                    <RedirectButton variant="text" color="tertiary">
                        Ver más
                    </RedirectButton>
                </Link>
                <ButtonsWrapper
                    className="glide__arrows"
                    data-glide-el="controls"
                >
                    <button
                        className="glide__arrow glide__arrow--left"
                        data-glide-dir="<"
                    >
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </button>
                    <button
                        className="glide__arrow glide__arrow--right"
                        data-glide-dir=">"
                    >
                        <IconButton>
                            <ArrowForward />
                        </IconButton>
                    </button>
                </ButtonsWrapper>
            </ControlsWrapper>
        </Wrapper>
    );
};

export default Slider;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 430px;
`;

const SliderWrapper = styled.div`
    height: fit-content;
    display: grid;
`;

const ControlsWrapper = styled.div`
    display: flex;
    gap: 1rem;
    position: absolute;
    margin: auto;
    top: -10px;
    right: 0;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    column-gap: 16px;

    svg {
        width: 20px;
        height: 20px;
        color: #616161;
    }
`;

const List = styled.ul`
    height: fit-content;
`;

const Item = styled.li`
    display: grid;
    place-items: center;
    height: 400px;
    width: 320px;
    padding: 0 0.2rem;
`;

const RedirectButton = styled(Button)`
    width: 120px;
    border-radius: var(--border-radius_rounded);
`;
