import React from "react";
import useInstructors from "../../../../hooks/teacher/useInstructors";
import SimpleLoading from "../../../common/SimpleLoading";
import SubjectInstructors from "../../../../pages/subject/components/SubjectInstructors";

const Instructors = (props) => {
  const { idCourse } = props;

  const { instructors } = useInstructors(idCourse);
  if (!instructors) {
    return <SimpleLoading />;
  }

  return (
    <div>
      <SubjectInstructors descr={{ instructors }} />
    </div>
  );
};

export default Instructors;
