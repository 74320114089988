import styled from "styled-components";
import AnnouncementCard from "./AnnouncementCard";
import { useState } from "react";

const AnnouncementsSection = (props) => {

    const { subjectName, card, general = false } = props;

    const [openModal, setOpenModal] = useState(false)

    const today = new Date()
    const publishedDate = new Date(card?.published_date)

    return (
        <>
            {
                today >= publishedDate && <AnnouncementCard subject_name={subjectName} general={general} card={card} setOpenModal={setOpenModal} />
            }
            {
                openModal &&
                <ModalContainer>
                    <ModalWindow>
                        <AnnouncementCard general={general} card={card} isOpen={openModal} setOpenModal={setOpenModal} />
                        <CloseModalButton onClick={() => setOpenModal(false)}>Volver</CloseModalButton>
                    </ModalWindow>
                </ModalContainer>
            }

        </>

    )
}

export default AnnouncementsSection;

const ModalContainer = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: calc(100vw - 6rem);
    height: 100vh;
    background-color: #00000030;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
`

const ModalWindow = styled.div`
    width: 100%;
    max-width: 70vw;
    height: fit-content;
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 2rem;
    border-radius: 20px;
`

const CloseModalButton = styled.button`
    cursor: pointer;
    width: 88px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-color: #b31d15;
`