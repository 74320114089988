import React from 'react';
import styled from 'styled-components';
import { Text } from '../../common/Texts';

const SelectRol = (props) => {
    const { icon, color, name, handleLogin, value, disabled = false } = props;

    if (disabled) {
        return <Card >
            <Circle color={'#aaa'}>{icon}</Circle>
            <Text fontSize="1.6rem" fontWeight="800" color={'#aaa'}>
                {name}
            </Text>
        </Card>
    }

    return (
        <Card onClick={() => handleLogin(value)}>
            <Circle color={color}>{icon}</Circle>
            <Text fontSize="1.6rem" fontWeight="800">
                {name}
            </Text>
        </Card>
    );
};

export default SelectRol;

const Card = styled.div`
    width: 200px;
    height: 210px;
    border-radius: 20px;
    border: 1px solid #eeeeee;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    :hover {
        background-color: #fafafa;
        box-shadow: 3px 4px 12px -6px rgba(158, 158, 158, 0.75);
        -webkit-box-shadow: 3px 4px 12px -6px rgba(158, 158, 158, 0.75);
        -moz-box-shadow: 3px 4px 12px -6px rgba(158, 158, 158, 0.75);
    }
    @media(max-width: 768px){
        width: calc(100% - 60px);
        height: 100px;
        margin: 0 30px;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
`;

const Circle = styled.div`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: ${(p) => p.color};
    display: flex;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    svg {
        color: #fff;
        font-size: 3rem;
    }
    @media(max-width: 768px){
        width: 60px;
        height: 60px;
        svg {
            font-size: 2rem;
        }
    }
`;
