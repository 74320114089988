import React from 'react';
import styled from 'styled-components';
import DefaultCard from "../common/DefaultCard";

function Card() {
    return (
        <CardContainer />
    );
}

const ProgramsSkt = () => {
    return (
        <CardWrapper>
            <Header>
                <InputFake/>
                <InputFake/>
                <InputFake/>
                <InputFake/>
            </Header>
            <Container>
                <Card />
                <Card />
                <Card />
            </Container>
        </CardWrapper>
    );
};

export default ProgramsSkt;

const CardWrapper = styled(DefaultCard)`
    background-color: transparent !important;
    box-shadow: none !important;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: auto;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
    border-radius: 20px;
    height: 200px;
    background-color: #f1f1f1;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const Header = styled.div`
    height: 120px;
    display: flex;
    gap: 16px;
    flex-direction: row;
`

const InputFake = styled.div`
    width: 120px;
    height: 35px;
    background-color: #f1f1f1;
    border-radius: 5px;
`