import React, { useState } from "react";
// import CardProgramStudent from '../../../common/teacher-academic/CardProgramStudent';
import styled from "styled-components";
import SearchBar from "../../../common/teacher-academic/SearchBar";
import SplitButton from "../../../common/SplitButton";
import AddButton from "../../../common/teacher-academic/AddButton";
import GridContent from "../../../common/teacher-academic/GridContent";
import SimpleLoading from "../../../common/SimpleLoading";
// import axios from 'axios';
// import ErrorResponse from '../../../common/ErrorResponse';
import ProgramCard from "../../../ui/molecules/profile/ProgramCard";
import SkeletonTabsGridAA from "../../../common/teacher-academic/SkeletonTabsGridAA";

const Programs = (props) => {
    const { programs } = props;
    const options = ["Más antiguo", "Más nuevo"];

    const [currentFilter, setCurrentFilter] = useState({
        index: 1,
        name: "Más nuevo",
    });

    //const [data, setData] = useState([]);
    const [values, setValues] = useState(programs);

    const handleSearchValue = (args) => {
        setValues(args);
    };

    //Order
    const handleOption = (args) => {
        setCurrentFilter(args);
    };

    //RETURN
    if (!values) {
        return (
            <Container>
                <SkeletonTabsGridAA />
            </Container>
        );
    }
    return (
        <Container>
            <Header>
                <SearchBar
                    propsValues={programs}
                    handleSearchValue={handleSearchValue}
                    title="Buscar programas"
                    filterNames={[
                        "name",
                        "progress",
                        "finished",
                        "pending",
                        "type",
                        "inProgress",
                    ]}
                />
            </Header>
            <AutoScroll>
                {!!values &&
                    values.map((value, index) => (
                        <ProgramCard key={index} program={value} />
                    ))}
            </AutoScroll>
        </Container>
    );
};

export default Programs;

const Container = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Header = styled.div`
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 1rem;

    div {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        padding-right: 1rem;
    }

    @media (min-width: 769px) and (max-width: 980px) {
        width: 80%;
    }
`;

const AutoScroll = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 2rem;
    padding-top: 1rem;
    padding-right: 0.5rem;
    max-height: 600px;
    overflow: auto;

    @media (max-width: 980px) {
        display: flex;
        flex-direction: column;
    }

    ::-webkit-scrollbar {
        background-color: #f9f9f9;
        border-radius: 6px;
        width: 4px;
    }
`;
