import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SimpleLoading from "../../../components/common/SimpleLoading";
//import subject from '../../ui/organisms/subject/dataSubjects';
import SubjectTabs from "./SubjectTabs";
// import TrainingRoute from "../../ui/organisms/subject/TrainingRoute";

export default function MainSubject(props) {
  const { subjectSelected, idCourse, origin, programId, resetData } = props;

  const [totalValue, setTotalValue] = useState(null);
  const [totalProgress, setTotalProgress] = useState(null);
  const [arrayModules100, setArrayModules100] = useState([]);

  const subject = subjectSelected.length !== 0 ? subjectSelected.courses : null;

  //EFECTS
  useEffect(() => {
    if (subject !== null) {
      progress(subject);
      modules100(subject);
    }
    // eslint-disable-next-line
  }, []);

  const progress = async (subject) => {
    setTotalProgress(
      subject.reduce(
        (sum, value) =>
          !!value?.survey?.done ? sum + 100 : sum + value.progress,
        0
      )
    );
    setTotalValue(subject.length);
  };

  const modules100 = (subject) => {
    const result = subject.filter(
      (s) => s.progress >= 100 || !!s?.survey?.done
    );
    setArrayModules100(result);
  };

  if (subjectSelected === undefined || subjectSelected === null) {
    return <SimpleLoading />;
  }
  if (totalProgress === null && totalValue === null) {
    return <SimpleLoading />;
  }
  return (
    <MainSubjectWrapper>
      {/* <Banner
                subjectProgress={
                    totalProgress !== null && totalValue !== null
                        ? (100 * totalProgress) / (totalValue * 100)
                        : 0
                }
                totalValue={totalValue}
                subject={subjectSelected}
            /> */}
      {/* <TrainingRoute
                arrayModules50={arrayModules50}
                arrayModules100={arrayModules100}
                subject={subjectSelected}
            /> */}
      <SubjectTabs
        arrayModules100={arrayModules100}
        subject={subjectSelected}
        idCourse={idCourse}
        origin={origin}
        programId={programId}
        totalProgress={totalProgress}
        resetData={resetData}
      />
    </MainSubjectWrapper>
  );
}

const MainSubjectWrapper = styled.div`
  /* display: grid; */
  position: relative;
  row-gap: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: -32px;
  }
`;
