import React from "react";
import styled from "styled-components";
import {
    Typography,
    AccordionDetails,
    AccordionSummary,
    Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory, useParams } from "react-router-dom";
import * as encode from "nodejs-base64-encode";

const ActivityCalification = (props) => {
    const { data } = props;

    const history = useHistory();

    let { id } = useParams();

    const moduleId = encode.encode(data.id + "", "base64");

    return (
        <Wrapper>
            <AccordionFake>
                <AccordionSummaryFake
                    expandIcon={<ExpandMoreIconFake />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <TypographyFake>
                        Módulo: <br />
                        <b>{data.name}</b>
                    </TypographyFake>
                </AccordionSummaryFake>
                <AccordionDetailsFake>
                    {/* cabecera de cada calificación */}
                    <Header>
                        <h3>Referencia</h3>
                        <h3>Fecha entrega</h3>
                        <h3>Estado</h3>
                        <h3>tipo de actividad</h3>
                        <h3>Calificación</h3>
                    </Header>
                    {data.activities.map((activity) => (
                        <Container
                            key={activity.id}
                            onClick={() => {
                                history.push(
                                    `../curso/${moduleId}==?id=${id}&resource=${activity.id}&origin=plan`
                                );
                            }}
                        >
                            {/* Nombre de actividad */}
                            <Title>{activity.name}</Title>
                            {/* Fecha de entrega */}
                            {activity.submissions.length > 0 ? (
                                <DataWrapper>
                                    {
                                        activity.submissions.sort((a, b) => {
                                            return b.grade - a.grade;
                                        })[0]?.delivery_date
                                    }
                                    {/* {activity.submissions.delivery_date} */}
                                </DataWrapper>
                            ) : (
                                <DataWrapper>-</DataWrapper>
                            )}
                            {/* Estado de la entrega */}
                            {activity.submissions.length > 0 ? (
                                activity.submissions.sort((a, b) => {
                                    return b.grade - a.grade;
                                })[0]?.state === "corregido" &&
                                !activity.submissions.sort((a, b) => {
                                    return b.grade - a.grade;
                                })[0]?.redo ? (
                                    <StatusWrapper type="corregido">
                                        Calificado
                                    </StatusWrapper>
                                ) : activity.submissions.sort((a, b) => {
                                      return b.grade - a.grade;
                                  })[0]?.state === "corregido" &&
                                  activity.submissions.sort((a, b) => {
                                      return b.grade - a.grade;
                                  })[0]?.redo ? (
                                    <StatusWrapper type="rehacer">
                                        Rehacer
                                    </StatusWrapper>
                                ) : activity.submissions.sort((a, b) => {
                                      return b.grade - a.grade;
                                  })[0]?.state === "auto_corregido" ? (
                                    <StatusWrapper type="auto_corregido">
                                        Auto Corregido
                                    </StatusWrapper>
                                ) : (
                                    <StatusWrapper type="nocalificado">
                                        Pendiente calificar
                                    </StatusWrapper>
                                )
                            ) : (
                                <StatusWrapper type="pendiente">
                                    Pendiente
                                </StatusWrapper>
                            )}

                            {/* Tipo de actividad */}
                            {activity.submissions.sort((a, b) => {
                                return b.grade - a.grade;
                            })[0]?.state === "auto_corregido" ? (
                                <ChipType>Auto corregible</ChipType>
                            ) : (
                                <ChipType>Presentación</ChipType>
                            )}
                            {/* Nota de la actividad */}
                            {activity.submissions.length > 0 ? (
                                <CalificationWrapper>
                                    {
                                        activity.submissions.sort((a, b) => {
                                            return b.grade - a.grade;
                                        })[0]?.grade
                                    }
                                </CalificationWrapper>
                            ) : (
                                <CalificationWrapper>-</CalificationWrapper>
                            )}
                        </Container>
                    ))}
                </AccordionDetailsFake>
            </AccordionFake>
        </Wrapper>
    );
};

export default ActivityCalification;

const Wrapper = styled.div`
    margin-bottom: 1rem;
    border: none !important;
`;

const AccordionDetailsFake = styled(AccordionDetails)`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .MuiCollapse-wrapper {
        border: none !important;
    }
`;
const Container = styled.div`
    display: grid;
    width: 100%;
    min-height: 70px;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    grid-template-columns: 3.5fr 1fr 1fr 1fr 1fr;
    gap: 0.5rem;
    text-align: center;
    border: solid 1px #f9f9f9;

    @media (max-width: 768px) {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }
`;

const Title = styled.div`
    color: #222222;
    font-size: 1rem;
    text-align: start;
    margin-left: 1rem;
`;

const DataWrapper = styled.div`
    color: #222222;
    font-size: 1rem;
    text-align: center;
`;

const StatusWrapper = styled.div`
    background-color: ${(p) =>
        p.type === "corregido"
            ? "#35d0a5"
            : p.type === "pendiente"
            ? "#DB4437"
            : p.type === "auto_corregido"
            ? "#86A0E4"
            : p.type === "nocalificado"
            ? "#d67539"
            : "#b31d15"};
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: white;
    font-size: ${(p) => (p.type === "nocalificado" ? "0.6rem" : "0.8rem")};
    text-transform: uppercase;
    font-weight: 700;
    width: fit-content;
    margin: 0 auto;
`;

const CalificationWrapper = styled.div`
    color: #222222;
    font-size: 1rem;
    text-align: center;
    font-weight: 700;
`;

const Header = styled.div`
    display: grid;
    width: 100%;
    height: 70px;
    overflow: hidden;
    align-items: center;
    background-color: #f9f9f9;
    border: solid 1px #f9f9f9;
    grid-template-columns: 3.5fr 1fr 1fr 1fr 1fr;
    text-align: center;
    gap: 0.5rem;

    @media (max-width: 768px) {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    }

    h3 {
        color: #616161;
        text-transform: uppercase;
        font-size: 0.9rem;
    }

    h3:nth-child(0n + 1) {
        text-align: start;
        margin-left: 1rem;
    }
`;

const AccordionFake = styled(Accordion)`
    border-radius: 10px !important;
    box-shadow: none;
`;

const AccordionSummaryFake = styled(AccordionSummary)`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    box-shadow: none;
    border-radius: 6px;
    background-color: #eee;
`;
const TypographyFake = styled(Typography)`
    color: #222222;
    font-size: 0.9rem;
    font-weight: bold;
`;

const ExpandMoreIconFake = styled(ExpandMoreIcon)`
    color: #b31d15;
`;

const ChipType = styled.div`
    width: fit-content;
    font-size: 0.8rem;
    color: #5e80db;
    background-color: #fff;
    border: solid 2px #5e80db;
    margin: 0 auto;
    border-radius: 5px;
    padding: 0.3rem 0.3rem;
`;
