import React, { useEffect, useState } from "react";
import Text from "../../../common/Text";
import Button from "@mui/material/Button";
import { Span } from "../../../common/Texts";
import styled from "styled-components";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Form, Formik } from "formik";
import { registerNewSchema } from "../../../../utils/schemas";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DefaultField from "../../../common/DefaultField";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/actions";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../../utils/snackStyles";

const RegisterForm = (props) => {
  // PROPS
  const { handleTab } = props;

  const initialValues = {
    full_name: "",
    email: "",
    password: "",
  };

  // STATE
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar] = useSnackbar(optionsStyle);

  // REDUX
  const dispatch = useDispatch();
  const { loadingRegister, errorRegister, authToken } = useSelector(
    (state) => state.auth
  );

  // EFFECTS
  useEffect(() => {
    if (!!errorRegister) {
      const error = errorRegister.response.data.error.detail;
      if (error === "El mail ya existe en FusionAuth") {
        openSnackbar("Este correo electrónico ya esta en uso");
      } else {
        openSnackbar("¡Ocurrió un error! Volver a intentar");
      }
    }
  }, [errorRegister, dispatch]);

  useEffect(() => {
    if (!!authToken) {
      openSnackbar("Cuenta creada correctamente. ¡Revisar correo electrónico!");
      dispatch(authActions.clearErrorRegister());
      setTimeout(() => {
        handleTab(0);
      }, 5000);
    }
  }, [authToken, dispatch, handleTab]);

  // FUNCTIONS
  const handleSubmit = (values) => {
    const body = sanitizarData(values);
    const bodyRequest = {
      form: body,
      extra_data: {},
    };
    dispatch(authActions.registerUserRequest(bodyRequest));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const sanitizarData = (object) => {
    const names = object.full_name.split(" ");
    return {
      first_name: names[0],
      last_name: names[names.length - 1],
      email: object.email.toLowerCase(),
      password: object.password,
      language: lenguaje().toLowerCase(),
    };
  };

  const lenguaje = () => {
    const lenguaje = window.navigator.language;
    return lenguaje[0] + lenguaje[1];
  };

  return (
    <>
      <Content>
        <Text fontWeight="900" fontSize="1.5rem" color="#B31D15">
          ¡Empieza tu ruta formativa!
        </Text>
        <Text fontWeight="500" textColor="#999999" fontSize="1rem">
          Crea tu cuenta y comienza tu futuro hoy
        </Text>
      </Content>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={registerNewSchema}
      >
        {() => (
          <FormContainer>
            {/** EMAIL */}
            <TextFieldFake
              name="full_name"
              type="text"
              label="Nombre completo"
              required
            />
            {/** Password */}
            <TextFieldFake
              name="email"
              type="email"
              label="Correo electrónico"
              required
            />
            <TextFieldFake
              name="password"
              type={showPassword ? "text" : "password"}
              label="Contraseña"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={toggleShowPassword}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ButtonWrapper>
              {!loadingRegister ? (
                <Button variant="contained" type="submit" color="primary">
                  Crear cuenta
                </Button>
              ) : (
                <CircularProgress />
              )}
            </ButtonWrapper>
          </FormContainer>
        )}
      </Formik>
      <Text fontWeight="500" textColor="#999999" fontSize="1.1rem">
        ¿Ya tienes una cuenta?{" "}
        <SpanFake onClick={() => handleTab(0)} fontWeight="600" color="#b31d15">
          Iniciar sesión
        </SpanFake>
      </Text>
    </>
  );
};

export default RegisterForm;

const Br = styled.div`
  height: ${(p) => p.h};
`;

const TextFieldFake = styled(DefaultField)`
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin: 0.5rem 0;
  display: flex;
`;

const SpanFake = styled(Span)`
  cursor: pointer;
  margin-left: 0.8rem;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: calc(100% + 7rem);
`;

const Content = styled.div`
  margin: 16px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    margin: 10px;
    max-height: 100px;
  }
`;
