import React from "react";
import Text from "../../../common/Text";
import Button from "@mui/material/Button";
import { Span } from "../../../common/Texts";
import styled from "styled-components";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import { Form, Formik } from "formik";
import { loginSchema } from "../../../../utils/schemas";
import { useState } from "react";
import DefaultField from "../../../common/DefaultField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/actions";
import { useEffect } from "react";
import { checkRolesLogin } from "../../../../helpers/roles.helper";
import SelectRolModal from "./SelectRolModal";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../../utils/snackStyles";

const ALAB_URL = process.env.REACT_APP_ALAB;

const LoginForm = (props) => {
  // PROPS
  const { handleTab, registration } = props;

  // FORMIK
  const initialValues = {
    user: "",
    password: "",
  };

  // STATE
  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModal] = useState(false);

  // REDUX
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar(optionsStyle);
  const { loadingLogin, errorRoles, token, roles, error, loadingGetUser } =
    useSelector((state) => state.auth);

  useEffect(() => {
    if (!!errorRoles && !loadingLogin ) {
      openSnackbar("Usuario y/o contraseña incorrectos");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRoles, dispatch, loadingLogin]);

  useEffect(() => {
    if (error) {
      openSnackbar("¡Ocurrió un error al acceder con este usuario!");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!!token) {
      handleCheckRol(roles);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, roles]);

  // FUNCTIONS
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values) => {
    dispatch(authActions.loginTokenRequest(values));
  };

  const handleCheckRol = (roles) => {
    const rol = checkRolesLogin(roles);
    if (!!rol) setModal(rol);
    else handleDirectLogin(roles[0]);
  };

  const handleLogin = (rol) => {
    if (rol === "ondemand") {
      window.open(
        `${ALAB_URL}/autologin?rol=ondemand&token=${token}&redirecturi=/campus`,
        "_self"
      );
    } else {
      dispatch(authActions.getDataRequest(rol));
    }
  };

  const handleDirectLogin = (rol) => {
    if (rol === "ondemand") {
      window.open(
        `${ALAB_URL}/autologin?rol=ondemand&token=${token}&redirecturi=/campus`,
        "_self"
      );
    } else {
      dispatch(authActions.getDataRequest(rol));
    }
  };

  return (
    <Wrapper>
      <Content>
        <Text fontWeight="900" fontSize="1.5rem" color="#B31D15">
          ¡Hola de nuevo!
        </Text>
        <Text fontWeight="500" textColor="#999999" fontSize="1rem">
          Que alegría verte de nuevo por aquí
        </Text>
      </Content>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={loginSchema}
      >
        {() => (
          <FormContainer>
            <TextFieldFake
              name="user"
              type="email"
              placeholder="Ingresa tu correo electrónico"
              required
            />
            {/** Password */}
            <TextFieldFake
              name="password"
              type={showPassword ? "text" : "password"}
              required
              label="Contraseña"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={toggleShowPassword}>
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <ButtonWrapper>
              {!loadingLogin && !loadingGetUser && (
                <LoginButton variant="contained" type="submit" color="primary">
                  Ingresar
                </LoginButton>
              )}
              {loadingLogin && !loadingGetUser && (
                <Center>
                  <CircularProgress size="1.3rem" color="secondary" />{" "}
                  <Text fontSize="1rem" fontWeight="600" textColor="#bfbfbf">
                    Ingresando
                  </Text>
                </Center>
              )}
              {loadingGetUser && loadingLogin && (
                <Center>
                  <CircularProgress size="1.3rem" color="secondary" />{" "}
                  <Text fontSize="1rem" fontWeight="600" textColor="#bfbfbf">
                    Obteniendo información
                  </Text>
                </Center>
              )}
              <Button onClick={() => handleTab(2)}>Olvidé mi contraseña</Button>
            </ButtonWrapper>
          </FormContainer>
        )}
      </Formik>
      {registration && (
        <Text fontWeight="500" textColor="#999999" fontSize="1.1rem">
          ¿Aún no tienes cuenta?{" "}
          <SpanFake
            onClick={() => handleTab(1)}
            fontWeight="600"
            color="#b31d15"
          >
            Registrarme
          </SpanFake>
        </Text>
      )}
      {!!modal && (
        <SelectRolModal
          rolOpen={modal}
          loadingGetUser={loadingGetUser}
          open={!!modal}
          handleLogin={handleLogin}
        />
      )}
    </Wrapper>
  );
};

export default LoginForm;

const TextFieldFake = styled(DefaultField)`
  max-width: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const LoginButton = styled(Button)`
  width: min-content;
`;

const SpanFake = styled(Span)`
  cursor: pointer;
  margin-left: 0.8rem;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 16px 0 26px 0;

  @media (max-width: 768px) {
    margin: 10px;
    max-height: 100px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 7rem);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
