/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from 'react';
import { userAction } from '../../../helpers/userActions.helper';
import { getWorkshopById } from '../../../redux/api/workshops';
import { useQueryParams } from '../../../modules/acropolisCommon/hooks/useQueryParams';
import * as encode from 'nodejs-base64-encode';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { utilsActions } from '../../../redux/actions';
import CourseContext from '../context/CourseSubjectContext';

const useCourse = (hash) => {
    const dispatch = useDispatch();
    const likeType = useSelector((state) => state.utils.likeType);
    const { activeRol } = useSelector((state) => state.auth);
    const repoId = useSelector((state) => state.auth.user.repo_id);
    const gradeForOndemand =
        useSelector(
            (state) => state?.ondemand?.ondemand?.grade_for_certificate
        ) || 1;

    const params = useQueryParams();
    const activity = params.get('activity');
    const origin = params.get('origin');
    const [activityId, setActivityId] = useState(
        activity ? encode.decode(activity, 'base64') : ''
    );

    const { subjectId } = useContext(CourseContext);

    const [workshop, setWorkshop] = useState(null);
    const [grade, setGrade] = useState(null);
    const [progress, setProgress] = useState(null);
    const [lastResourceOfCourse, setLastResourceOfCourse] = useState(null);

    const [currentMedia, setCurrentMedia] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [menuItems, setMenuItems] = useState([]);
    const [currentUnit, setCurrentUnit] = useState(null);
    const [nextResource, setNextResource] = useState(null);
    const [unitsEnabled, setUnitsEnabled] = useState([]);
    const [openAside, setOpenAside] = useState(true);
    const [blockResocurce, setblockResocurce] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);

    useEffect(() => {
        if (workshop === null) {
            getWorkshopByIdRequest(hash);
        } else {
            userAction({
                object_id: hash + '',
                object: workshop.nombre_publicacion,
                name: workshop.nombre_publicacion,
                activity: workshop.es_workshop
                    ? 'Ingreso Workshop'
                    : 'Ingreso OA',
                medio: workshop.es_workshop ? 'Ingreso Workshop' : 'Ingreso OA',
            });
            countLastResourceOfCourse(workshop);
            setGrade(workshop?.grade ? workshop.grade : null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workshop, hash]);

    useEffect(() => {
        if (workshop?.unidades) {
            handleNextDisabled(workshop.unidades);
        }
        // eslint-disable-next-line
    }, [currentMedia, workshop]);

    useEffect(() => {
        if (workshop !== null) {
            setCurrentMedia(currentPosition);
            handleNextDisabled(workshop.unidades);
            unitCurrentNow(currentPosition);
            workshop?.unidades.forEach((unit) => {
                unit?.clases.forEach((clase) => {
                    // eslint-disable-next-line eqeqeq
                    if (
                        activityId == clase?.id ||
                        activityId == clase?.id_recurso ||
                        activityId == clase?.id_evaluacion
                    ) {
                        changeCurrentMedia(clase);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPosition]);

    useEffect(() => {
        if (workshop !== null) {
            handleNextDisabled(workshop.unidades);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeStatus]);

    const getWorkshopByIdRequest = async (id) => {
        try {
            const response = await getWorkshopById(id, origin, subjectId);
            setWorkshop(response.data.response_data);
        } catch (error) {
            console.log(error);
        }
    };

    // FUNCTIONS
    const toggleAside = () => {
        setOpenAside(!openAside);
    };

    const handleCloseAside = () => {
        setOpenAside(true);
    };

    const blockResourceChange = (status) => {
        setblockResocurce(status);
    };

    // Boton siguiente del modal del audiovisual
    const handleNext = () => {
        nextItem();
    };

    const nextItem = () => {
        setCurrentPosition(handleSearchResourcePosition(currentPosition, true));
    };

    const backItem = () => {
        setCurrentPosition(
            handleSearchResourcePosition(currentPosition, false)
        );
    };

    const countLastResourceOfCourse = (workshop) => {
        let totalResource = 0;
        let allView = 0;
        workshop.unidades.forEach((unit) => {
            unit.clases.forEach((classe) => {
                ++totalResource;
                if (classe.visto) {
                    ++allView;
                }
                setProgress(
                    (allView * 100) / (totalResource === 0 ? 1 : totalResource)
                );
                setLastResourceOfCourse(
                    totalResource === 0 ? totalResource : totalResource - 1
                );
            });
        });
    };

    //
    const unitCurrentNow = (nowClass) => {
        let status = null;
        workshop.unidades.forEach((unidad, u) => {
            unidad.clases.forEach((clase, c) => {
                if (clase.orden === nowClass.orden) {
                    setCurrentUnit(unidad.nombre);
                    // Obtener siguiente recursos
                    status = workshop.unidades[u].clases[c + 1];
                    if (status === undefined) {
                        // Si es el último recurso setea el anterior
                        if (
                            workshop.unidades[u].clases[c].orden ===
                            lastResourceOfCourse
                        ) {
                            status = workshop.unidades[u].clases[c - 1];
                        } else {
                            status = 'class';
                            if (status === 'class') {
                                status = workshop.unidades[u + 1]?.clases[0];
                                if (status === undefined) {
                                    status = null;
                                }
                            }
                        }
                    }
                }
            });
        });
        setNextResource(status);
    };

    const changeCurrentMedia = (newClass) => {
        let url = new URL(window.location.href);

        url.searchParams.delete('activity');

        window.history.replaceState(null, '', url.toString());

        setActivityId('');

        if (blockResocurce === false) {
            if (likeType !== null) {
                dispatch(
                    utilsActions.resourceRequestLikes({
                        id: currentMedia.id,
                        type: likeType,
                    })
                );
            }
            menuItems.forEach((item, index) => {
                if (
                    item.orden === newClass.orden &&
                    item.nombre_publicacion === newClass.nombre_publicacion
                ) {
                    setCurrentPosition(newClass);
                }
            });
            handleCloseAside();
            handleNextDisabled(workshop.unidades);
        }
    };

    const handleNextDisabled = (units) => {
        let unitsEnables = [];
        units.forEach((unit, index) => {
            const isEnable = unit.clases.filter((cl) => cl?.visto === true);
            if (index === 0) {
                unitsEnables = [...unitsEnables, unit.id];
            }
            if (isEnable.length === unit.clases.length && index === 0) {
                unitsEnables = units[index + 1]
                    ? [...unitsEnables, unit.id, units[index + 1].id]
                    : [...unitsEnables, unit.id];
            }
            if (isEnable.length === unit.clases.length && index !== 0) {
                unitsEnables = units[index + 1]
                    ? [...unitsEnables, unit.id, units[index + 1].id]
                    : [...unitsEnables, unit.id];
            }
        });
        setUnitsEnabled(unitsEnables);
    };

    // RECORRE LOS RECURSOS
    const handleSearchResourcePosition = (positionNow, action) => {
        let result = positionNow;
        menuItems.forEach((item, index) => {
            if (item === positionNow) {
                if (item.orden === lastResourceOfCourse) {
                    // Si es el único recurso, se setea ese mismo
                    if (menuItems.length === 1) {
                        setCurrentPosition(menuItems[index], false);
                    }
                    // Si es el último recurso se setea el mismo
                    if (menuItems.length > 1) {
                        // setCurrentPosition(menuItems[index - 1], true);
                        // result = menuItems[action ? index + 1 : index - 1];
                        result = menuItems[index];
                    }
                } else {
                    result = menuItems[action === true ? index + 1 : index - 1];
                }
            }
        });
        return result;
    };

    const handleCheckResource = (name) => {
        let ws = { ...workshop };
        ws.unidades.forEach((unidad) => {
            unidad.clases.forEach((clase) => {
                if (clase.nombre_publicacion === name) {
                    clase.visto = true;
                }
            });
        });
        setWorkshop(ws);
    };

    const DISABLE_CERTIFICATE_ORIGIN_CASES = ['plan', 'diplomado'];

    const certificateEnabled =
        activeRol === 'ondemand' &&
        origin === 'alab' &&
        !!grade &&
        grade >= gradeForOndemand
            ? true
            : activeRol === 'ondemand' && origin === 'alab' && !grade
            ? true
            : activeRol === 'ondemand' && origin !== 'alab'
            ? true
            : activeRol !== 'ondemand' &&
              !DISABLE_CERTIFICATE_ORIGIN_CASES.includes(origin)
            ? true
            : false;

    return {
        repoId,
        workshop,
        setWorkshop,
        grade,
        progress,
        setProgress,
        lastResourceOfCourse,
        currentMedia,
        setCurrentMedia,
        currentPosition,
        setCurrentPosition,
        changeCurrentMedia,
        toggleAside,
        handleCloseAside,
        unitsEnabled,
        openAside,
        blockResourceChange,
        blockResocurce,
        setMenuItems,
        menuItems,
        unitCurrentNow,
        handleNext,
        backItem,
        nextItem,
        handleCheckResource,
        currentUnit,
        nextResource,
        certificateEnabled,
        changeStatus,
        setChangeStatus,
    };
};

export default useCourse;
