import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Text from '../../../components/common/Text';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { Button } from '@mui/material';
import useElectives from '../../../hooks/useElectives';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as encode from 'nodejs-base64-encode';
import { Icon } from '@iconify/react';
import ElectiveSelectionModal from '../../../components/ui/organisms/subject/ElectiveSelectionModal';
import ModalConfirmElectives from '../../../components/ui/organisms/subject/ModalConfirmElectives';
import CardElectives from '../../../components/ui/organisms/subject/CardElectives';
import ModalConfirmCourseInPerson from './modals/ModalConfirmCourseInPerson';

const ElectiveSubject = (props) => {
    const { electives, handleClick, electivesFormated, resetData, disabled } =
        props;

    //REDUX
    const repoId = useSelector((state) => state.auth.user.repo_id);

    // HOOKS
    const { postSelectElective, error, loading } = useElectives();
    const screenWidth = useWindowWidth();
    let { id } = useParams();

    const idAssignment = encode.decode(id, 'base64');

    // STATES
    const [showElectives, setShowElectives] = useState(
        electivesFormated.length === 0 ? false : true
    );
    const [optionCheck, setOptionCheck] = useState('');
    const [nameSelected, setNameSelected] = useState('');
    const [hasCourseInPerson, setHasCourseInPerson] = useState(false);
    const [multiSelectedItems, setMultiSelectedItems] = useState([]);
    let arrayElectives = [];

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState({
        isOpen: false,
        type: null,
    });
    const [openModalHasCourseInPerson, setOpenModalHasCourseInPerson] = useState(false)

    useEffect(() => {
        if (error === false) {
            handleClose();
            resetData();
        }

        if (error === true) {
            handleClose();
        }
        setShowElectives(true)
        // eslint-disable-next-line
    }, [error]);

    const handleCreateElectives = () => {
        if (!!optionCheck) {
            arrayElectives.push({ oa_id: optionCheck, oa_principal: true });
        }

        multiSelectedItems.forEach((item) => {
            arrayElectives.push({ oa_id: item.id, oa_principal: false });
        });

        postSelectElective(repoId, {
            type: 'elective',
            asignatura_id: Number(idAssignment),
            oa_electives: arrayElectives,
        });
    };

    // FUNCTIONS

    // MANEJO DE ESTADOS DE LOS MODALES Y ACORDEON

    const handleOpen = () => {

        const findHasCourseInPerson = multiSelectedItems.find((item) => item.hasInCoursePerson === true)

        if (hasCourseInPerson || findHasCourseInPerson) {
            setOpenModalHasCourseInPerson(true)
        } else {
            setOpen(true);
        }
    };

    const handleAcceptCourseInPerson = () => {
        setOpenModalHasCourseInPerson(false)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseCourseInPerson = () => {
        setOpenModalHasCourseInPerson(false)
    }

    const handleOpenModal = (value, type) => {
        setOpenModal({
            isOpen: value,
            type: type,
        });
    };

    const handleCloseModal = () => {
        setOpenModal({
            isOpen: false,
            type: null,
        });
    };

    // ENVIA LA MATRICULACIÓN
    const handlePost = () => {
        handleCreateElectives();
    };

    // MANEJA EL ESTADO DE ELECTIVAS COMPLEMENTARIAS
    const handleMultiSelectionChange = (id, name, hasInCoursePerson) => {
        const index = multiSelectedItems.findIndex((item) => item.id === id);
        if (index === -1) {
            setMultiSelectedItems([...multiSelectedItems, { id, name, hasInCoursePerson }]);
        } else {
            const updatedItems = [...multiSelectedItems];
            updatedItems.splice(index, 1);
            setMultiSelectedItems(updatedItems);
        }
    };

    return (
        <>
            {electives.length > 0 ? (
                <Card disabled={disabled}>
                    <ModalConfirmCourseInPerson
                        open={openModalHasCourseInPerson}
                        handleClose={handleCloseCourseInPerson}
                        handleClick={handleAcceptCourseInPerson}
                        loading={loading}
                    />
                    <ModalConfirmElectives
                        open={open}
                        nameSelected={nameSelected}
                        handleClose={handleClose}
                        handlePost={handlePost}
                        error={error}
                        loading={loading}
                    />
                    <ElectiveSelectionModal
                        open={openModal.isOpen}
                        multiSelectedItems={multiSelectedItems}
                        handleMultiSelectionChange={handleMultiSelectionChange}
                        handleClose={handleCloseModal}
                        type={openModal.type}
                        electivesFormated={electivesFormated}
                        electives={electives}
                        setNameSelected={setNameSelected}
                        setOptionCheck={setOptionCheck}
                        optionCheck={optionCheck}
                        setHasCourseInPerson={setHasCourseInPerson}
                    />
                    <Header>
                        <TextWrapper type="">
                            <Text
                                fontSize={
                                    screenWidth > 1024 || screenWidth < 768
                                        ? '14px'
                                        : '12px'
                                }
                                fontWeight="500"
                                color="#616161"
                            >
                                Bloque de electiva
                            </Text>
                            <ModuleName
                                title={'Escoge el módulo que deseas cursar'}
                            >
                                {electivesFormated.length === 0
                                    ? 'Selecciona las electivas que deseas cursar'
                                    : 'Electivas confirmadas'}
                            </ModuleName>
                        </TextWrapper>
                    </Header>
                    <Body showElectives={showElectives}>
                        <Fieldset showElectives={showElectives}>
                            <ContainerTitleElective>
                                <TitleElective>
                                    Electiva principal
                                </TitleElective>
                                {electivesFormated.length === 0 && (
                                    <MoreButton
                                        onClick={() => {
                                            handleOpenModal(true, 'main');
                                        }}
                                    >
                                        <Icon
                                            icon={
                                                !optionCheck
                                                    ? 'carbon:add-filled'
                                                    : 'mdi:pencil-circle'
                                            }
                                        />
                                    </MoreButton>
                                )}
                            </ContainerTitleElective>
                            <SelectorContainer>
                                {electivesFormated.length > 0 ? (
                                    <ContainerFullElectives>
                                        {electivesFormated.map((elective) => {
                                            if (elective.main_elective) {
                                                return (
                                                    <CardElectives
                                                        key={elective.id}
                                                        elective={elective}
                                                        handleClick={
                                                            handleClick
                                                        }
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                    </ContainerFullElectives>
                                ) : (
                                    <>
                                        {!optionCheck ? (
                                            <EmptyElective />
                                        ) : (
                                            <FullElective>
                                                <IconInfo icon="ic:round-info" />
                                                <h3>{nameSelected}</h3>
                                            </FullElective>
                                        )}
                                    </>
                                )}
                            </SelectorContainer>
                            <ContainerTitleElective>
                                <TitleElective>
                                    Electivas complementarias
                                </TitleElective>
                                <MoreButton
                                    disabled={
                                        nameSelected === '' &&
                                        electivesFormated.length === 0
                                    }
                                    onClick={() => {
                                        handleOpenModal(true, 'complementary');
                                    }}
                                >
                                    <Icon
                                        icon={
                                            multiSelectedItems.length === 0
                                                ? 'carbon:add-filled'
                                                : 'mdi:pencil-circle'
                                        }
                                    />
                                </MoreButton>
                            </ContainerTitleElective>
                            {electivesFormated.length > 0 && (
                                <SelectorContainer>
                                    {electivesFormated.map((elective) => {
                                        if (!elective.main_elective) {
                                            return (
                                                <CardElectives
                                                    key={elective.id}
                                                    elective={elective}
                                                    handleClick={handleClick}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </SelectorContainer>
                            )}

                            {multiSelectedItems.length === 0 ? (
                                <SelectorContainer>
                                    {electivesFormated.length === 0 ? (
                                        <EmptyElective />
                                    ) : (
                                        <Reminder>
                                            <Icon icon="mingcute:alert-fill" />
                                            <p>
                                                <span bg="#b31d15">
                                                    ¡Recuerda!
                                                </span>{' '}
                                                Puedes seleccionar{' '}
                                                <span bold bg="#616161">
                                                    todas las Electivas
                                                    complementarias
                                                </span>{' '}
                                                que desees.
                                            </p>
                                        </Reminder>
                                    )}
                                </SelectorContainer>
                            ) : (
                                <>
                                    {multiSelectedItems.map(
                                        (elective, index) => (
                                            <SelectorContainer
                                                key={elective.id}
                                            >
                                                <FullElective
                                                    isFirst={
                                                        index === 0
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <IconContainer>
                                                        {elective.type ===
                                                        'Bloque Contenido IA' ? (
                                                            <Icon icon="tabler:input-ai" />
                                                        ) : elective.type ===
                                                          'Bloque Presencial' ? (
                                                            <Icon icon="bx:been-here" />
                                                        ) : (
                                                            <Icon icon="tdesign:gesture-click" />
                                                        )}
                                                    </IconContainer>
                                                    <h3>{elective.name}</h3>
                                                </FullElective>
                                            </SelectorContainer>
                                        )
                                    )}
                                </>
                            )}
                        </Fieldset>
                        <Footer>
                            {electivesFormated.length === 0 ? (
                                <CustomButton
                                    disabled={
                                        electivesFormated.length > 0
                                            ? multiSelectedItems.length > 0
                                                ? false
                                                : true
                                            : multiSelectedItems.lengt === 0 ||
                                              !optionCheck
                                            ? true
                                            : false
                                    }
                                    onClick={handleOpen}
                                    variant="contained"
                                    color="primary"
                                >
                                    Confirmar electivas
                                </CustomButton>
                            ) : multiSelectedItems.length > 0 ? (
                                <CustomButton
                                    disabled={
                                        electivesFormated.length > 0
                                            ? multiSelectedItems.length > 0
                                                ? false
                                                : true
                                            : multiSelectedItems.lengt === 0 ||
                                              !optionCheck
                                            ? true
                                            : false
                                    }
                                    onClick={handleOpen}
                                    variant="contained"
                                    color="primary"
                                >
                                    Confirmar electivas
                                </CustomButton>
                            ) : null}
                        </Footer>
                    </Body>
                </Card>
            ) : null}
        </>
    );
};

export default ElectiveSubject;

const Card = styled.div`
    width: 100%;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08),
        0px 6px 12px -4px rgba(24, 39, 75, 0.2);
    outline: none;
    background-color: ${(props) => (!props.disabled ? '#fff' : '#dddddd')};
    width: 100%;
    border-radius: 20px !important;
    z-index: 5;
    display: flex;
    flex-direction: column;
    opacity: ${(props) => (!props.disabled ? '1' : '0.5')};
    pointer-events: ${(props) => props.disabled && 'none'};
`;

const Header = styled.div`
    padding: 2.3rem 2rem;
    box-sizing: border-box;
    display: flex;
    width: 100%;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 1.5rem 0;
    }
`;

const Body = styled.div`
    display: ${(p) => (p.showElectives ? 'block' : 'none')};
    padding: 0 calc(2rem + 20px) 2rem calc(2rem + 20px);
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${(p) => (p.type === 'survey' ? '30px' : '5px')};
    white-space: ${(p) => (p.type === 'survey' ? 'none' : 'nowrap')};
    overflow-wrap: break-word;
    padding-left: 20px;
    max-height: 100%;
    width: ${(p) => (p.type === 'survey' ? 'fit-content' : '370px')};

    @media screen and (max-width: 1440px) {
        width: ${(p) => (p.type === 'survey' ? '260px' : '250px')};
    }

    @media screen and (max-width: 1024px) {
        width: ${(p) => (p.type === 'survey' ? '220px' : '220px')};
    }

    @media screen and (max-width: 768px) {
        min-width: ${(p) => (p.type === 'survey' ? '100%' : '90%')};
        padding-left: 0;
    }
`;

const ModuleName = styled.p`
    font-size: 20px;
    font-weight: 700;
    width: 100%;
    color: #b31d15;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
`;

const Fieldset = styled.fieldset`
    display: flex;

    flex-direction: column;
    gap: 0.8rem;
    border: none;
    width: 100%;
`;

const TitleElective = styled.h2`
    font-size: 1rem;
`;

const SelectorContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;
const EmptyElective = styled.div`
    width: 100%;
    height: 75px;
    border-radius: 20px;
    background-color: #f9f9f9;
    border: dashed 1px #dcdcdc;
`;
const MoreButton = styled.button`
    height: 30px;
    width: 30px;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

    &:disabled {
        svg {
            color: #f1f1f1;
        }
    }

    svg {
        font-size: 30px;
        color: #b31d15;
    }
`;

const IconInfo = styled(Icon)`
    color: #b31d15;
    min-height: 30px;
    min-width: 30px;
    font-size: 24px;
`;

const FullElective = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    border: solid 1px #dcdcdc;
    height: 75px;
    border-radius: 20px;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
`;

const Footer = styled.div`
    display: flex;
    padding-top: 1rem;
    box-sizing: border-box;
    justify-content: flex-end;
`;

const CustomButton = styled(Button)`
    border-radius: 10px;
`;

const ContainerFullElectives = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5rem;
    flex-direction: column;
`;

const ContainerTitleElective = styled.div`
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
`;

const IconContainer = styled.div`
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 50%;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #fff;
        font-size: 1.3rem;
    }
`;

const Reminder = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    svg {
        color: #b31d15;
        font-size: 1.5rem;
    }

    p {
        font-size: 1.2rem;
        color: #616161;

        span {
            font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
            color: ${(p) => p.bg};
        }
    }
`;
