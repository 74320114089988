import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Button } from '@mui/material';
import Text from '../../../components/common/Text';
import { Icon } from '@iconify/react';
import { EventAvailable, EventBusy } from '@mui/icons-material';
import ModalCourseConditions from '../../../components/ui/organisms/subject/ModalCourseConditions';
import useWindowWidth from '../../../hooks/useWindowWidth';
import ModalProblem from '../../../components/common/modals/ModalProblem';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const SubjectInfoCard = (props) => {
    const {
        subject: {
            name,
            duration,
            courses,
            progress,
            fecha,
            fecha_vencimiento,
            condiciones_cursado,
            enrollment_state,
            enrollment_nota_final = 90,
            rework_activities = false,
        },
    } = props;

    const COUNTRY_LOCALE = 'es-AR';
    const options = { timeZone: 'UTC' };
    const pathname = window.location.href;

    let expiringDate = new Date(fecha_vencimiento + 'T00:00:00');

    expiringDate.setHours(23);
    expiringDate.setMinutes(59);
    expiringDate.setSeconds(59);

    const isExpired = expiringDate <= new Date(Date.now());

    const screenWidth = useWindowWidth();

    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [openModalExpired, setOpenModalExpired] = useState(false);
    const [openModalBloqued, setOpenModalBloqued] = useState(false);

    const formattedBeginDate = new Date(fecha).toLocaleDateString(
        COUNTRY_LOCALE,
        options
    );
    const formattedexpirationDate = new Date(
        fecha_vencimiento
    ).toLocaleDateString(COUNTRY_LOCALE, options);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickModal = () => {
        history.push('/support');
    };

    return (
        <>
            <ModalProblem
                isOpen={openModalExpired}
                title={'Asignatura vencida'}
                description={
                    'Lo sentimos, ya expiró el plazo para cursar esta asignatura. Para poder continuar con tu cursado, deberás comunicarte con tu '
                }
                handleCloseModal={() => setOpenModalExpired(false)}
                handleClick={handleClickModal}
            />

            <ModalProblem
                isOpen={openModalBloqued}
                title={'Asignatura bloqueada'}
                description={
                    'Lo sentimos, para poder continuar con tu cursado, deberás comunicarte con tu '
                }
                handleCloseModal={() => setOpenModalBloqued(false)}
                handleClick={handleClickModal}
            />
            <ModalCourseConditions
                courseConditions={condiciones_cursado}
                open={open}
                handleClose={handleClose}
            />
            <CardWrapper>
                {screenWidth <= 768 && (
                    <TopContent>
                        <Text
                            fontSize={
                                screenWidth > 1024 || screenWidth < 768
                                    ? '16px'
                                    : '14px'
                            }
                            fontWeight="700"
                        >
                            ASIGNATURA
                        </Text>
                        <Text
                            fontSize={
                                screenWidth > 1024 || screenWidth < 768
                                    ? '24px'
                                    : '20px'
                            }
                            fontWeight="700"
                            color="#B31D15"
                        >
                            {name}
                        </Text>
                    </TopContent>
                )}
                <ProgressWrapper>
                    <AssignmentProgress
                        color="primary"
                        variant="determinate"
                        value={Math.trunc(progress?.progress)}
                        // size={150}
                        sx={{ backgroundColor: '' }}
                    />
                    <InfoWrapper>
                        <ProgressPercentage>
                            {Math.trunc(progress?.progress)}%
                        </ProgressPercentage>
                        <Text
                            fontSize={
                                screenWidth > 1024
                                    ? '14px'
                                    : screenWidth > 768
                                    ? '12px'
                                    : '14px'
                            }
                        >
                            Mi progreso
                        </Text>
                    </InfoWrapper>
                </ProgressWrapper>
                <AssignmentInfo>
                    <LeftInfo>
                        {screenWidth > 768 && (
                            <TopContent>
                                <SubjectInfo>
                                    <Text
                                        fontSize={
                                            screenWidth > 1024 ||
                                            screenWidth < 768
                                                ? '16px'
                                                : '14px'
                                        }
                                        fontWeight="700"
                                    >
                                        ASIGNATURA
                                    </Text>
                                    {enrollment_state?.toLowerCase() ===
                                    'egresado' ? (
                                        <>
                                            <InfoChip
                                                backgroundColor="#EAFAF6"
                                                color="#1E8065"
                                            >
                                                <Icon
                                                    icon="ic:round-check"
                                                    width="1.2em"
                                                    height="1.2em"
                                                />
                                                Aprobada
                                            </InfoChip>
                                            <Grade>
                                                <Icon
                                                    icon="ph:medal-fill"
                                                    width="1.2em"
                                                    height="1.2em"
                                                    style={{ color: '#B31D15' }}
                                                />
                                                <p>
                                                    Nota:{' '}
                                                    <b>
                                                        {enrollment_nota_final}
                                                    </b>{' '}
                                                    / 100
                                                </p>
                                            </Grade>
                                        </>
                                    ) : enrollment_state?.toLowerCase() ===
                                      'baja' ? (
                                        <ErrorWrapper>
                                            <InfoChip
                                                backgroundColor="#FCE9E8"
                                                color="#B31D15"
                                            >
                                                <Icon
                                                    icon="fa6-regular:calendar-xmark"
                                                    width="1.2em"
                                                    height="1.2em"
                                                />
                                                Bloqueada
                                            </InfoChip>
                                            <button
                                                onClick={() =>
                                                    setOpenModalBloqued(true)
                                                }
                                            >
                                                ¿Por qué está bloqueada?
                                            </button>
                                        </ErrorWrapper>
                                    ) : (
                                        isExpired && (
                                            <ErrorWrapper>
                                                <InfoChip
                                                    backgroundColor="#FCE9E8"
                                                    color="#B31D15"
                                                >
                                                    <Icon
                                                        icon="fa6-regular:calendar-xmark"
                                                        width="1.2em"
                                                        height="1.2em"
                                                    />
                                                    Vencida
                                                </InfoChip>
                                                <button
                                                    onClick={() =>
                                                        setOpenModalExpired(
                                                            true
                                                        )
                                                    }
                                                >
                                                    ¿Por qué está vencida?
                                                </button>
                                            </ErrorWrapper>
                                        )
                                    )}
                                </SubjectInfo>
                                <Text
                                    fontSize={
                                        screenWidth > 1024 ? '24px' : '20px'
                                    }
                                    fontWeight="700"
                                    color="#B31D15"
                                >
                                    {name}
                                </Text>
                            </TopContent>
                        )}
                        <BottomContent>
                            <ModulesAmount>
                                <Icon
                                    color="#B31D15"
                                    width={screenWidth > 1024 ? 25 : 16}
                                    height={screenWidth > 1024 ? 25 : 16}
                                    icon="ion:book"
                                />
                                <Text
                                    fontSize={
                                        screenWidth > 1024 ? '16px' : '12px'
                                    }
                                >
                                    {courses.length} Módulos
                                </Text>
                            </ModulesAmount>
                            <Divider color="#B31D15" />
                            <AssignmentDuration>
                                <Icon
                                    color="#B31D15"
                                    width={screenWidth > 1024 ? 25 : 20}
                                    height={screenWidth > 1024 ? 25 : 20}
                                    icon="mingcute:time-fill"
                                />
                                <Text
                                    fontSize={
                                        screenWidth > 1024 ? '16px' : '12px'
                                    }
                                >
                                    {duration === '0 False'
                                        ? '_'
                                        : `${duration} de cursado`}
                                </Text>
                            </AssignmentDuration>
                        </BottomContent>
                    </LeftInfo>
                    <RightInfo>
                        <DateInfo>
                            {rework_activities && (
                                <ReworkActivitiesChip>
                                    <Icon
                                        icon="mingcute:alert-line"
                                        width="24px"
                                        height="24px"
                                    />
                                    Tienes actividades por rehacer
                                </ReworkActivitiesChip>
                            )}
                            <DateWrapper>
                                <EventAvailable />
                                <TextWrapper>
                                    <Text
                                        color="#A8A8A8"
                                        fontSize={
                                            screenWidth > 1024 ||
                                            screenWidth < 768
                                                ? '14px'
                                                : '10px'
                                        }
                                        fontWeight="700"
                                    >
                                        {'INICIO'}
                                    </Text>
                                    {fecha ? (
                                        <DateText>
                                            {formattedBeginDate}
                                        </DateText>
                                    ) : (
                                        <DateText>-</DateText>
                                    )}
                                </TextWrapper>
                            </DateWrapper>
                            <Divider color="#B31D15" />
                            <DateWrapper>
                                <EventBusy />
                                <TextWrapper>
                                    <Text
                                        color="#A8A8A8"
                                        fontSize={
                                            screenWidth > 1024 ||
                                            screenWidth < 768
                                                ? '14px'
                                                : '10px'
                                        }
                                        fontWeight="700"
                                    >
                                        {'VENCIMIENTO'}
                                    </Text>
                                    {fecha_vencimiento ? (
                                        <DateText>
                                            {formattedexpirationDate}
                                        </DateText>
                                    ) : (
                                        <DateText>-</DateText>
                                    )}
                                </TextWrapper>
                            </DateWrapper>
                        </DateInfo>
                        <ButtonWrapper>
                            <CertificateButton
                                color="primary"
                                disabled={false}
                                variant="contained"
                                startIcon={<CalendarTodayIcon />}
                                onClick={() => {
                                    if (pathname.includes('tab=0')) {
                                        window.location.href = pathname.replace(
                                            /(tab=)0/,
                                            '$14'
                                        );
                                    } else {
                                        window.location.href = `${pathname}&tab=4`;
                                    }
                                }}
                            >
                                Agendar consulta personalizada
                            </CertificateButton>
                            <CertificateButton
                                onClick={handleClick}
                                variant="contained"
                                disabled={!condiciones_cursado ? true : false}
                            >
                                <BadgeIcon
                                    icon="mdi:book-outline"
                                    width={screenWidth > 1024 ? 25 : 16}
                                    height={screenWidth > 1024 ? 25 : 16}
                                />
                                Condiciones de cursado
                            </CertificateButton>
                        </ButtonWrapper>
                    </RightInfo>
                </AssignmentInfo>
                <BackgroundRounded />
            </CardWrapper>
        </>
    );
};

export default SubjectInfoCard;

const CardWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    max-width: 100%;
    height: 300px;
    padding: 0 5rem;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.2),
        0px 8px 12px -6px rgba(24, 39, 75, 0.12);
    z-index: 5;

    @media (max-width: 1440px) {
        grid-template-columns: 1fr 3fr;
        padding: 0 2rem;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 2rem;
        gap: 3rem;
        height: 100%;
    }
`;

const ProgressWrapper = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;

    @media (max-width: 1024px) {
        width: 150px;
        height: 150px;
    }

    @media (max-width: 768px) {
        width: 190px;
        height: 190px;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const AssignmentProgress = styled(CircularProgress)`
    width: 200px !important;
    height: 200px !important;

    @media (max-width: 1024px) {
        width: 150px !important;
        height: 150px !important;
    }

    @media (max-width: 768px) {
        width: 190px !important;
        height: 190px !important;
    }
`;

const InfoWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    top: -4.5px;
    margin: auto;
    text-align: center;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background-color: #f9f9f9;

    @media (max-width: 1440px) {
    }
`;

const ProgressPercentage = styled.h2`
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    font-size: 50px;
    color: #b31d15;

    @media (max-width: 1024px) {
        font-size: 30px;
    }
    @media (max-width: 768px) {
        font-size: 45px;
    }
`;

const AssignmentInfo = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;

    @media (max-width: 1440px) {
        /* justify-content: flex-start; */
    }
`;

const LeftInfo = styled.div`
    display: flex;
    max-height: 100%;
    justify-content: space-around;
    /* align-items: flex-start; */
    flex-direction: column;
    padding: 2rem 0;

    @media (max-width: 1024px) {
        padding: 3rem 0;
    }
`;

const TopContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const SubjectInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const InfoChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: ${(props) => props.backgroundColor};
    height: 32px;
    padding: 0px 12px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.color};
`;

const Grade = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    p {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        margin-left: -6px;
        white-space: nowrap;

        b {
            font-weight: 900;
        }
    }
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;

    button {
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: #cd2118;

        :hover {
            font-weight: 700;
        }
    }
`;

const BottomContent = styled.div`
    display: flex;
    gap: 35px;

    @media (max-width: 1024px) {
        gap: 7px;
    }
    @media (max-width: 768px) {
        padding: 0;
        display: none;
    }
`;

const ModulesAmount = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;

    @media (max-width: 1024px) {
        gap: 7px;
    }
`;

const AssignmentDuration = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;

    @media (max-width: 1024px) {
        gap: 7px;
    }
`;

const Divider = styled.div`
    width: 2px;
    height: 35px;
    background-color: #b31d15;
`;

const RightInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    z-index: 5;
`;

const DateInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;

    svg {
        color: #616161;
        width: 20px;
        height: 20px;

        @media (max-width: 1024px) {
            width: 17px;
            height: 17px;
        }
    }

    @media (max-width: 1024px) {
        gap: 4px;
    }

    @media (max-width: 768px) {
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

const DateText = styled.span`
    color: #616161;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const CertificateButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0.6rem 0.7rem;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 1024px) {
        font-size: 12px;
    }
`;

const BadgeIcon = styled(Icon)`
    width: 20px;
    height: 20px;
`;

const BackgroundRounded = styled.span`
    position: absolute;
    width: 100%;
    clip-path: ellipse(26rem 110% at right center);
    height: 100%;
    right: 0;
    border-radius: 0 20px 20px 0;
    background-color: #f9f9f9;
    z-index: 0;

    @media (max-width: 1440px) {
        clip-path: ellipse(24rem 110% at right center);
    }

    @media (max-width: 1024px) {
        clip-path: ellipse(17.5rem 110% at right center);
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const ReworkActivitiesChip = styled.div`
    position: absolute;
    top: 8rem;
    min-height: 40px;
    font-size: 13px;
    font-weight: 700;
    padding: 0 1rem;
    border-radius: 100px;
    background-color: #fff;
    color: #b31d15;
    border: 1px solid #b31d15;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0px 8px 24px -4px #18274b14, 0px 6px 12px -6px #18274b1f;
    z-index: 10;

    svg {
        color: #b31d15;
    }
`;
