import styled from "styled-components";
import { Field, Form, Formik } from "formik";

const ModalCreateAnnouncement = (props) => {

    const { initialValues, handleSubmitAnnouncement, announcementSchema, setOpenModal, loading, error } = props;

   

    return (
        <Modal>
            <h1>Nuevo anuncio</h1>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitAnnouncement}
                validationSchema={announcementSchema}
            >
                {
                    ({ values, setFieldValue }) => (
                        <FormData>
                            <label>Asunto</label>
                            <Input
                                name="title"
                                type="text"
                                label="Asunto"
                                placeholder="Introduce un título"
                                value={values.title}
                                setFieldValue={setFieldValue}
                                required
                            />
                            <label>Anuncio</label>
                            <Field
                                name="message"
                                as={InputTextArea}
                                label="Anuncio"
                                placeholder="Redacta tu anuncio"
                                value={values.message}
                                setFieldValue={setFieldValue}
                                required
                            />
                            <label>Fecha de publicación y caducidad</label>
                            <p>Si deseas puedes programar cuándo quieres que se publique y despublique el anuncio.</p>
                            <section>
                                <div>
                                    <label>Fecha de publicación:</label>
                                    <Input
                                        name="published_date"
                                        type="date"
                                        label="Asunto"
                                        placeholder="Introduce un título"
                                        value={values.publishDate}
                                        setFieldValue={setFieldValue}
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Fecha de caducidad:</label>
                                    <Input
                                        name="expiration_date"
                                        type="date"
                                        label="Asunto"
                                        placeholder="Introduce un título"
                                        value={values.expirationData}
                                        setFieldValue={setFieldValue}
                                        required
                                    />
                                </div>
                            </section>
                            <ButtonsWrapper>
                                <ModalButton variant="secondary" onClick={() => setOpenModal(false)}>Cancelar</ModalButton>
                                <ModalButton type="submit">
                                    {
                                        loading ? "Cargando..." : "Publicar anuncio"
                                    }
                                </ModalButton>
                            </ButtonsWrapper>
                        </FormData>
                    )
                }
            </Formik>
            {
                !!error && <ErrorMsg>{error}</ErrorMsg>
            }
        </Modal>
    )
}

export default ModalCreateAnnouncement;

const Modal = styled.div`
    position: fixed;
    width: calc(50vw - 4rem);
    min-height: calc(70vh - 4rem);
    max-height: 1000px;
    height: fit-content;
    top: 30rem;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 9999;
    box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 2rem;

    h1 {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
        margin-left: 0;
    }

    label {
        font-size: 18px;
        font-weight: 700;
        color: #222;
    }

    section {
        display: flex;
        gap: 2rem;

        div {
            display: flex;
            flex-direction: column;

            label {
                font-family: Lato;
                font-size: 14px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.005em;
                text-align: left;
                color: #616161;
            }

            input {
                width: 312px;
            }
        }
    }

    @media (width < 1540px) {
        top: 22rem;
    }
`

const FormData = styled(Form)`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const InputTextArea = styled.textarea`
    width: calc(50vw - 8rem);
    max-height: 300px !important;
    height: 300px !important;
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 1rem 1rem;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    scrollbar-color: transparent transparent;
    line-height: 24px;

    @media (width <= 1540px) {
        height: 130px !important;
    }
`

const Input = styled(Field)`
    width: calc(50vw - 8rem);
    max-height: 700px;
    height: auto;
    border: 1px solid #a2a2a2;
    border-radius: 10px;
    padding: 1rem 1rem;
    font-size: 16px;
    box-sizing: border-box;
    resize: none;
    scrollbar-color: transparent transparent;
    line-height: 24px;
`

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 1rem;
`

const ModalButton = styled.button`
    cursor: pointer;
    border-radius: 8px;
    background-color: ${(props) => props.variant === "secondary" ? "#fff" : "#b31d15"};
    color: ${(props) => props.variant === "secondary" ? "#b31d15" : "#fff"};
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    border: ${(props) => props.variant === "secondary" && "1px solid #b31d15"};

    :hover {
        background-color: ${(props) => props.variant !== "secondary" && "#CD2118"}; 
    }
`

const ErrorMsg = styled.div`
    position: absolute;
    bottom: 2.5rem;
    left: 1rem;
    color: #CD2118;
    
    @media (width <= 1540px) {
        font-size: 14px;
    }
`