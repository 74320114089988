import React from "react";
import Text from "../../../common/Text";
import Button from "@mui/material/Button";
import styled from "styled-components";
import {
    CircularProgress,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { Form, Formik } from "formik";
import { loginSchema } from "../../../../utils/schemas";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../redux/actions";
import { useEffect } from "react";
import { ErrorMessage, Field } from "formik";
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from "../../../../utils/snackStyles";

const LoginForm = (props) => {

    const { primaryColor } = props
    // FORMIK
    const initialValues = {
        user: "",
        password: "",
    };

    // STATE
    const [showPassword, setShowPassword] = useState(false);

    // REDUX
    const dispatch = useDispatch();
    const [openSnackbar] = useSnackbar(optionsStyle);
    const {
        loadingLogin,
        errorRoles,
        roles,
        error,
        loadingGetUser,
    } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!!errorRoles) {
            openSnackbar("Usuario y/o contraseña incorrectos");
        }
    }, [errorRoles, dispatch]);

    useEffect(() => {
        if (error) {
            openSnackbar("¡Ocurrió un error al acceder con este usuario!");
        }
    }, [error]);

    // FUNCTIONS
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (values) => {
        dispatch(authActions.loginTokenRequest(values));
    };

    useEffect(() => {
        {
            !!roles &&
                (roles?.includes("ondemand")
                    ? handleDirectLogin("ondemand")
                    : handleDirectLogin(roles[0]));
        }
    }, [roles]);

    const handleDirectLogin = (rol) => {
        dispatch(authActions.getDataRequest(rol));
    };


    return (
        <Wrapper>
            <TextFake color={primaryColor}>Iniciar sesión</TextFake>

            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={loginSchema}
            >
                {() => (
                    <FormContainer>
                        {/* USER */}
                        <div>
                            <FieldFake
                                name="user"
                                type="email"
                                placeholder="Ingresa tu correo electrónico"
                                fullWidth
                            />
                            <ErrorMessage name="user" component={CustomError} />
                        </div>

                        {/* PASSWORD */}
                        <PassWrapper>
                            <FieldFake
                                name="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Contraseña"
                                fullWidth
                            />
                            <InputAdornmentFake position="end">
                                <IconButton
                                    size="small"
                                    onClick={toggleShowPassword}
                                >
                                    {showPassword ? (
                                        <Visibility fontSize="small" />
                                    ) : (
                                        <VisibilityOff fontSize="small" />
                                    )}
                                </IconButton>
                            </InputAdornmentFake>
                            <ErrorMessage
                                name="password"
                                component={CustomError}
                            />
                        </PassWrapper>

                        <ButtonWrapper>
                            {!loadingLogin && !loadingGetUser && (
                                <ButtonFake
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                >
                                    Ingresar
                                </ButtonFake>
                            )}
                            {loadingLogin && !loadingGetUser && (
                                <Center>
                                    <CircularProgress
                                        size="1.3rem"
                                        color="secondary"
                                    />{" "}
                                    <Text
                                        fontSize="1rem"
                                        fontWeight="600"
                                        textColor="#bfbfbf"
                                    >
                                        Ingresando
                                    </Text>
                                </Center>
                            )}
                            {loadingGetUser && loadingLogin && (
                                <Center>
                                    <CircularProgress
                                        size="1.3rem"
                                        color="secondary"
                                    />{" "}
                                    <Text
                                        fontSize="1rem"
                                        fontWeight="600"
                                        textColor="#bfbfbf"
                                    >
                                        Obteniendo información
                                    </Text>
                                </Center>
                            )}
                            <ButtonForgot
                                onClick={() =>
                                    window.open(
                                        "https://fa.aden.org/password/forgot"
                                    )
                                }
                            >
                                Olvidé mi contraseña
                            </ButtonForgot>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </Wrapper>
    );
};

export default LoginForm;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 100%;
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #b31d15;
    margin-left: 1rem;
`;
const PassWrapper = styled.div`
    position: relative;
`;
const FieldFake = styled(Field)`
    background-color: #efefef;
    font-size: 1rem;
    outline: none;
    border: none;
    padding: 1rem;
    height: 20px;
    width: calc(100% - 2rem);

    border-radius: 25px;
`;

const InputAdornmentFake = styled(InputAdornment)`
    position: absolute;
    top: 50%;
    right: calc(-10px + 2rem);
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: center;
    /* padding: 0 15rem; */
    width: 50%;

    @media (max-width: 1560px) {
        width: 60%;
    }
    @media (max-width: 1100px) {
        width: 80%;
    }
`;

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const ButtonFake = styled(Button)`
    border-radius: 10px;
    span {
        font-size: 1.3rem;
    }
`;

const ButtonForgot = styled(Button)`
    font-size: 1.5rem;
    font-weight: 300;
    color: #b1b1b1;
`;

const TextFake = styled.h3`
    color: ${p => p.color};
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
`;
