// REACT
import React, { useState, useEffect, useReducer } from 'react';

// ROUTER
import { useHistory, useParams } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';

// BASE64
import * as encode from 'nodejs-base64-encode';

// STYLES
import styled from 'styled-components';

// COMPONENTS
import { Card, Button } from '@mui/material';
import ModalAssignment from '../../../components/common/modals/ModalAssignment';
import DialogGenericFG from '../../../components/common/DialogGenericFG';
import Text from '../../../components/common/Text';
import LazyImg from '../../../modules/acropolisCommon/components/LazyImg';
import electiveImage from '../../../assets/media/aden/asignatura-electiva.png';
import ElectiveSubjectModal from '../../../pages/progress/components/ElectiveSubjectModal';
import electiveIcon from '../../../assets/media/svg/elective-icon.svg';
import { Icon } from '@iconify/react';
import ModalProblem from '../../../components/common/modals/ModalProblem';
import ModalPause from '../../../components/common/modals/ModalPause';
import { formatDate } from '../../../modules/acropolisCommon/utils/formatDate';
import ConfirmDialog from '../../ui/molecules/campus/DialogBlockDoc';

const SubjectsCard = (props) => {
    const {
        data,
        setDataAssigment,
        dataAssigment,
        setIsChange,
        dataProgramName,
        isDiplomat,
        setIsFlag,
        isFlag,
        programState,
        handleEnrollment,
        titulationState,
        statusStudentBlock,
    } = props;

    // VALIDATIONS
    const STATE_CASES = ['DEMO', 'confirmado', 'egresado'];
    const isNotCurrent = data?.assignment_without_information;
    const isDiplomado = dataProgramName.toLowerCase().includes('diplomado');

    // ROUTER
    const history = useHistory();
    const { id } = useParams();

    // REDUX
    const repo_id = useSelector((state) => state.auth.user.repo_id);

    // STATES
    const [openModal, setOpenModal] = useState(false);
    const [openModalExpired, setOpenModalExpired] = useState(false);
    const [openModalBloqued, setOpenModalBloqued] = useState(false);
    const [openElectiveModal, setOpenElectiveModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [openModalPause, setOpenModalPause] = useState(false);
    const [openModalTitulation, setOpenModalTitulation] = useState(false);

    const [state, subjectState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        { subjectType: null, is_demo: false, is_elective: false }
    );

    useEffect(() => {
        subjectTypeController();
        // eslint-disable-next-line
    }, [data]);

    // STATIC-DATA
    const idProgram = encode.decode(id, 'base64');
    const hash = encode.encode(data.id + '', 'base64');

    let expiringDate = new Date(data?.end_date + 'T00:00:00');

    expiringDate.setHours(23);
    expiringDate.setMinutes(59);
    expiringDate.setSeconds(59);

    const isExpired =
        expiringDate <= new Date(Date.now()) && data?.state === 'confirmado';
    const isBlocked = data?.state === 'baja';

    //FUNCTIONS
    /**
     * Establece el tipo de asignatura dependiendo el estado
     * que se le haya asignado en el repositorio
     */
    const subjectTypeController = () => {
        if (programState === 'DEMO')
            subjectState({
                subjectType: 'DEMO',
                is_demo: !data?.cursado_demo,
                is_elective: false,
            });

        if (data?.elective_subject)
            subjectState({
                subjectType: 'ELECTIVE',
                is_demo: false,
                is_elective: true,
            });

        if (!data?.elective_subject && programState !== 'DEMO')
            subjectState({
                subjectType: 'ACTIVE',
                is_demo: false,
                is_elective: false,
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleElectiveModal = () => {
        setOpenElectiveModal(!openElectiveModal);
        // if (openElectiveModal === true) {
        //   setDataProgram(null);
        //   setData(null);
        // }
    };

    const handleClick = () => {
        if (
            titulationState &&
            (data?.state === 'preinscripto' || data?.state === 'pendiente')
        ) {
            setOpenModalTitulation(true);
            return;
        }
        if (data.state === 'preinscripto') {
            setOpenModalPause(true);
        }

        if (STATE_CASES.includes(data?.state)) {
            history.push(
                `../asignatura/${hash}?program=${id}&origin=${
                    isDiplomat ? 'diplomado' : 'plan'
                }`
            );
            return;
        }
        if (data.elective_subject) {
            setOpenModal(!openModal);
            return;
        }
        if (data.state === 'pendiente') {
            setOpenModal(!openModal);
            return;
        }
    };

    const handleCloseModalPause = () => {
        setOpenModalPause(false);
    };

    const handleClickModal = () => {
        history.push('/support');
    };

    const ToggleButtons = ({ type, disabled }) => {
        const cases = {
            elective: (
                <SubjectButton
                    variant="contained"
                    onClick={handleElectiveModal}
                    disabled={disabled}
                >
                    Seleccionar
                </SubjectButton>
            ),
            demo: (
                <SubjectButton
                    variant="contained"
                    disabled={
                        state.is_demo ||
                        (data.progress === 0 &&
                            statusStudentBlock?.studentStatus === 'Bloqueado')
                    }
                    onClick={state.is_demo ? () => {} : handleClick}
                >
                    {state.is_demo
                        ? 'Bloqueado'
                        : data?.progress === 0
                        ? 'Comenzar'
                        : data?.progress === 100
                        ? 'Volver a ver'
                        : 'Continuar'}
                </SubjectButton>
            ),
            active: (
                <SubjectButton
                    variant="contained"
                    disabled={
                        ((isNotCurrent || isBlocked || isExpired) &&
                            data?.progress !== 100) ||
                        (data.progress === 0 &&
                            statusStudentBlock?.studentStatus === 'Bloqueado')
                    }
                    onClick={isNotCurrent ? () => {} : handleClick}
                >
                    {
                        data?.state === "pendiente"
                            ? "Iniciar curso"
                            : data?.progress === 0
                                ? "Comenzar"
                                : data?.progress === 100
                                    ? "Volver a ver"
                                    : "Continuar"}
                </SubjectButton>
            ),
        };

        return cases[type?.toLowerCase()] || cases['active'];
    };

    if (!!open) {
        return (
            <DialogGenericFG
                message={`No puede cursar más de dos materias en simultáneo`}
                open={open}
                title="Error al Inscribirse"
                handleClose={handleClose}
                customColor="#b31d15"
                titleColor="#b31d15"
                transitionType="fade"
            />
        );
    }

    return (
        <>
            <SubjectCardContainer
                notAvailable={data.assignment_without_information}
                title={
                    data.progress === 0 &&
                    statusStudentBlock?.studentStatus === 'Bloqueado'
                        ? `Lamentablemente, no puedes realizar esta actividad en este momento. El acceso está temporalmente bloqueado debido a ${statusStudentBlock?.blockDetail?.reason}`
                        : data.name
                }
                demoUnlocker={data?.cursado_demo}
                isDemo={state.subjectType}
            >
                {data?.rework_activities && (
                    <RedoActivity>
                        <Icon
                            icon="mingcute:alert-line"
                            width="24px"
                            height="24px"
                        />
                        Tienes actividades para rehacer
                    </RedoActivity>
                )}
                {!data?.elective_subject && (
                    <CourseStateChip
                        demo={
                            (!data?.cursado_demo && programState === 'DEMO') ||
                            isNotCurrent
                        }
                        progress={data?.progress}
                        preinscription={data?.state === 'pendiente'}
                    >
                        {data?.state === 'preinscripto'
                            ? 'En pausa'
                            : data?.state === 'pendiente'
                            ? 'Preinscripto'
                            : data?.progress === 0 || isNotCurrent
                            ? 'Por iniciar'
                            : data?.progress === 100
                            ? 'Finalizado'
                            : 'En curso'}
                    </CourseStateChip>
                )}

                {!data?.cursado_demo && programState === 'DEMO' && (
                    <DisablerCover />
                )}

                <ElectiveSubjectModal
                    open={openElectiveModal}
                    onClose={handleElectiveModal}
                    subjects={data?.subjects}
                    handleEnrollment={handleEnrollment}
                    repo_id={repo_id}
                />

                {openModal && (
                    <ModalAssignment
                        repo_id={repo_id}
                        handleClick={handleClick}
                        data={data}
                        idProgram={idProgram}
                        setDataAssigment={setDataAssigment}
                        dataAssigment={dataAssigment}
                        setIsChange={setIsChange}
                        setIsFlag={setIsFlag}
                        isFlag={isFlag}
                        isDiplomat={isDiplomat}
                    />
                )}
                <ModalPause
                    isOpen={openModalPause}
                    data={data}
                    idProgram={idProgram}
                    hash={hash}
                    id={id}
                    isDiplomat={isDiplomat}
                    handleCloseModal={handleCloseModalPause}
                />
                <ModalProblem
                    isOpen={openModalExpired}
                    title={'Asignatura vencida'}
                    description={
                        'Lo sentimos, ya expiró el plazo para cursar esta asignatura. Para poder continuar con tu cursado, deberás comunicarte con tu '
                    }
                    handleCloseModal={() => setOpenModalExpired(false)}
                    handleClick={handleClickModal}
                />
                <ModalProblem
                    isOpen={openModalBloqued}
                    title={'Asignatura bloqueada'}
                    description={
                        'Lo sentimos, para poder continuar con tu cursado, deberás comunicarte con tu '
                    }
                    handleCloseModal={() => setOpenModalBloqued(false)}
                    handleClick={handleClickModal}
                />
                <ConfirmDialog
                    handleCloseBlock={() => setOpenModalTitulation(false)}
                    openBlock={openModalTitulation}
                />
                <LazyImg
                    src={
                        data?.elective_subject
                            ? electiveImage
                            : !data.assignment_without_information
                            ? data.img
                            : '../../assets/aden/NotImageFound.png'
                    }
                    width="100%"
                    height="110px"
                />

                {!data?.elective_subject ? (
                    <Body>
                        {!data.assignment_without_information &&
                        STATE_CASES.includes(data.state) ? (
                            <ProgressWrapper>
                                <LinearProgressWrapper>
                                    <LinearProgress
                                        porcentaje={
                                            data?.progress > 100
                                                ? 100
                                                : Math.trunc(data?.progress)
                                        }
                                    />
                                </LinearProgressWrapper>
                                <Percentage>
                                    {data?.progress > 100
                                        ? 100
                                        : Math.trunc(data?.progress)}
                                    %
                                </Percentage>
                            </ProgressWrapper>
                        ) : (
                            <ProgressWrapper>
                                <LinearProgressWrapper>
                                    <LinearProgress porcentaje={0} />
                                </LinearProgressWrapper>
                                <Percentage>{0}%</Percentage>
                            </ProgressWrapper>
                        )}
                        {!data?.elective_subject && (
                            <Plan
                                notAvailable={
                                    data.assignment_without_information
                                }
                            >
                                {data.name || 'sin nombre'}
                            </Plan>
                        )}
                        <DetailsWrapper>
                            {isBlocked && data?.progress !== 100 ? (
                                <ErrorWrapper>
                                    <InfoChip
                                        backgroundColor="#FCE9E8"
                                        color="#B31D15"
                                    >
                                        <Icon
                                            icon="fa6-regular:calendar-xmark"
                                            width="1.2em"
                                            height="1.2em"
                                        />
                                        Bloqueada
                                    </InfoChip>
                                    <button
                                        onClick={() =>
                                            setOpenModalBloqued(true)
                                        }
                                    >
                                        ¿Por qué está bloqueada?
                                    </button>
                                </ErrorWrapper>
                            ) : isExpired && data?.progress !== 100 ? (
                                <ErrorWrapper>
                                    <section>
                                        <InfoChip
                                            backgroundColor="#FCE9E8"
                                            color="#B31D15"
                                        >
                                            <Icon
                                                icon="fa6-regular:calendar-xmark"
                                                width="1.2em"
                                                height="1.2em"
                                            />
                                            Vencida
                                        </InfoChip>
                                        <Icon
                                            icon="fa6-regular:calendar-xmark"
                                            width="1.2em"
                                            height="1.2em"
                                        />
                                        <Content>
                                            {data.state === 'egresado' ? (
                                                <p>Egreso</p>
                                            ) : (
                                                <p>Vencimiento</p>
                                            )}
                                            {data.state === 'confirmado' ? (
                                                <span>
                                                    {formatDate(
                                                        data?.end_date,
                                                        'DD/MM/YYYY'
                                                    )}
                                                </span>
                                            ) : data.state === 'egresado' ? (
                                                <span>
                                                    {formatDate(
                                                        data?.fecha_egreso,
                                                        'DD/MM/YYYY'
                                                    )}
                                                </span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </Content>
                                    </section>

                                    <button
                                        onClick={() =>
                                            setOpenModalExpired(true)
                                        }
                                    >
                                        ¿Por qué está vencida?
                                    </button>
                                </ErrorWrapper>
                            ) : data?.progress === 0 ? (
                                <Duration>
                                    <Icon
                                        icon="carbon:time"
                                        width="24px"
                                        height="24px"
                                    />
                                    {data?.duration.toLowerCase() +
                                        ' de cursado'}
                                </Duration>
                            ) : data?.state === 'egresado' ? (
                                /* Manejar cuando está egresado */
                                <GraduateWrapper>
                                    <InfoChip
                                        backgroundColor="#EAFAF6"
                                        color="#1E8065"
                                    >
                                        <Icon
                                            icon="ic:round-check"
                                            width="1.2em"
                                            height="1.2em"
                                        />
                                        Aprobada
                                    </InfoChip>
                                    <Grade>
                                        <Icon
                                            icon="ph:medal-fill"
                                            width="1.2em"
                                            height="1.2em"
                                        />
                                        <p>
                                            Nota: <b>{data?.nota_final}</b> /
                                            100
                                        </p>
                                    </Grade>
                                </GraduateWrapper>
                            ) : (
                                <>
                                    <CourseWrapper>
                                        <SubTitle>
                                            <Icon
                                                icon="fa6-regular:calendar-check"
                                                width="1.2em"
                                                height="1.2em"
                                            />
                                            <Content>
                                                <p>Inicio</p>
                                                {data.state === 'confirmado' ||
                                                data.state === 'egresado' ? (
                                                    <span>
                                                        {formatDate(
                                                            data?.start_date,
                                                            'DD/MM/YYYY'
                                                        )}
                                                    </span>
                                                ) : (
                                                    <span>xx/xx/xxxx</span>
                                                )}
                                            </Content>
                                        </SubTitle>
                                    </CourseWrapper>
                                    {!isDiplomado && (
                                        <CourseWrapper>
                                            {(data?.end_date ||
                                                data?.fecha_egreso) && (
                                                <SubTitle>
                                                    <Icon
                                                        icon="fa6-regular:calendar-xmark"
                                                        width="1.2em"
                                                        height="1.2em"
                                                    />
                                                    <Content>
                                                        {data.state ===
                                                        'egresado' ? (
                                                            <p>Egreso</p>
                                                        ) : (
                                                            <p>Vencimiento</p>
                                                        )}
                                                        {data.state ===
                                                        'confirmado' ? (
                                                            <span>
                                                                {formatDate(
                                                                    data?.end_date,
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </span>
                                                        ) : data.state ===
                                                          'egresado' ? (
                                                            <span>
                                                                {formatDate(
                                                                    data?.fecha_egreso,
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </Content>
                                                </SubTitle>
                                            )}
                                        </CourseWrapper>
                                    )}
                                </>
                            )}
                        </DetailsWrapper>
                    </Body>
                ) : (
                    <Body>
                        <ElectiveWrapper>
                            <ElectiveIcon src={electiveIcon} />
                            <TextWrapper>
                                <Text
                                    fontSize="20px"
                                    fontWeight="500"
                                    color="#B31D15"
                                >
                                    {data?.name}
                                </Text>
                                <Text
                                    fontSize="16px"
                                    fontWeight="500"
                                    color="#616161"
                                >
                                    {data?.subjects?.length
                                        ? 'Selección de electiva, escoge la asignatura que deseas cursar.'
                                        : 'Esperando la confirmación de asignaturas vigentes'}
                                </Text>
                            </TextWrapper>
                        </ElectiveWrapper>
                    </Body>
                )}
                <Footer>
                    {
                        data?.state === "pendiente" && (
                            <label>¡Confirma para iniciar!</label>
                        )
                    }
                    <ToggleButtons
                        type={state?.subjectType}
                        disabled={!data?.subjects?.length}
                    />
                </Footer>
            </SubjectCardContainer>
        </>
    );
};

export default SubjectsCard;

const SubjectCardContainer = styled(Card)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 325px;
    border-radius: 20px;
    background-color: #ffffff;
    transition: 0.3s all ease;
    cursor: ${(p) => (p.notAvailable ? 'default' : 'pointer')};

    cursor: ${(p) =>
        p.isDemo === 'DEMO'
            ? p.demoUnlocker
                ? 'pointer'
                : 'default'
            : 'pointer'};

    :hover {
        box-shadow: ${(p) =>
            p.isDemo === 'DEMO'
                ? p.demoUnlocker
                    ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    : 'none'
                : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};

        :before {
            opacity: ${(p) =>
                p.isDemo === 'DEMO' ? (p.demoUnlocker ? '1' : 'none') : '1'};
        }
    }
`;

const Body = styled.div`
    display: flex;
    background-color: white;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    gap: 1.5rem;
    padding: 0 1rem 1rem 1rem;
    padding-top: 1rem;
    z-index: 5;
    max-width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
        padding-top: 0rem;
        justify-content: center;
        gap: 1.7rem;
    }
`;

const Plan = styled.h2`
    font-size: 1rem;
    font-weight: 900;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: ${(p) => (p.notAvailable ? '#a8a8a8' : '#222222')};

    @media screen and (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;

const LinearProgressWrapper = styled.div`
    border-radius: 50px;
    width: 100%;
    height: 7px;
    background-color: #eaeaea;
`;

const LinearProgress = styled.div`
    border-radius: 50px;
    width: ${(props) => `${props.porcentaje}%`};
    height: 7px;
    background-color: #3bd39c;
`;

const Percentage = styled.span`
    font-size: 0.9rem;
    color: #3bd39c;
    flex-wrap: wrap;
`;

const DetailsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.7rem;
    width: 100%;
`;

const CourseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: max-content;
`;

const SubTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2rem;
    svg {
        color: #a8a8a8;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    p {
        color: #a8a8a8;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.6rem;
    }
    span {
        color: #616161;
        font-size: 0.7rem;
        font-weight: bold;
    }

    @media screen and (max-width: 768px) {
        p {
            font-size: 0.7rem;
        }
        span {
            font-size: 0.8rem;
        }
    }
`;

const DisablerCover = styled.div`
    position: absolute;
    height: 100%;
    min-width: 100%;
    z-index: 6;
    background-color: #eaeaea;
    opacity: 0.45;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    height: 50%;
    width: 100%;
    background-color: #fafafa;
    padding: 0.3rem;

    label {
        font-size: 12px;
        color: #A8A8A8;
        font-weight: 600;
    }
`;

const SubjectButton = styled(Button)`
    width: fit-content;
    height: fit-content;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`;

const CourseStateChip = styled.span`
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 0.4rem 0.7rem;
    z-index: 7;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.06px;
    border-radius: 8px;

    background-color: ${(p) =>
        p.preinscription
            ? '#FBF0EA'
            : p.demo
            ? '#C4C4C4'
            : p.progress === 0
            ? '#F9F5EC'
            : p.progress === 100
            ? '#EAFAF6'
            : '#E6EBF9'};

    color: ${(p) =>
        p.preinscription
            ? '#B75E26'
            : p.demo
            ? '#F1F1F1'
            : p.progress === 0
            ? '#C29F43'
            : p.progress === 100
            ? '#1E8065'
            : '#5E80DB'};
`;

const ElectiveIcon = styled.img`
    width: 50px;
    height: 50px;
`;

const ElectiveWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
`;

const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: #cd2118;
        :hover {
            font-weight: 700;
        }
    }
    section {
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
            color: #a8a8a8;
        }
    }
`;

const InfoChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: ${(props) => props.backgroundColor};
    height: 32px;
    padding: 0px 12px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.color};
    svg {
        color: var(--primary-one) !important;
    }
`;

const Duration = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #616161;
`;

const GraduateWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Grade = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    p {
        font-size: 14px;
        font-weight: 600;
        color: #222;
        margin-left: -6px;
        b {
            font-weight: 900;
        }
    }
`;

const RedoActivity = styled.div`
    min-height: 32px;
    position: absolute;
    z-index: 2;
    top: 4rem;
    left: 10px;
    height: fit-content;
    font-size: 12px;
    font-weight: 700;
    padding: 0 1rem;
    border-radius: 100px;
    background-color: #fff;
    color: #b31d15;
    border: 1px solid #b31d15;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0px 8px 24px -4px #18274b14, 0px 6px 12px -6px #18274b1f;

    @media (max-width: 768px) {
        top: 6rem;
        font-size: 0.83rem;
    }
`;
