import styled from "styled-components";

// Components
import { Text } from "../../../../components/common/Texts";

// Material UI
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Dialog } from "@mui/material";
import { useState } from "react";

const InfoOldSection = (props) => {

    const {
        valuesCorrection,
        studentComment,
        teacherFiles,
        idTask
    } = props

    const [openModalAudio, setOpenModalAudio] = useState(false)
    const [openModalVideo, setOpenModalVideo] = useState(false)

    // Sacar del componente "Attachments" la visualización de archivo, video y audio

    const handleShowFile = () => {
        window.open(`https://oerp-edu.aden.org/web/image?model=repo.aden.entrega.alumno&field=archivo_correccion&id=${idTask}`);
    }

    const handleShowVoiceNote = () => {
        setOpenModalAudio(true)
    }

    const handleShowVideo = () => {
        setOpenModalVideo(true)
    }

    return (
        <>
            <FakeDialog open={openModalAudio}>
                <ContentVideo>
                    <audio
                        style={{ position: "relative", top: "-8px" }}
                        controls
                    >
                        <source
                            src={`https://oerp-edu.aden.org/web/image?model=repo.aden.entrega.alumno&field=audio_correccion&id=${idTask}`}
                        ></source>
                    </audio>
                    <ModalButton variant="outlined" onClick={() => setOpenModalAudio(false)}>
                        Salir
                    </ModalButton>
                </ContentVideo>
            </FakeDialog>
            <FakeDialog open={openModalVideo}>
                    <ContentVideo>
                        <video
                            width="720"
                            className="video-player"
                            controls
                            autoplay
                            src={`https://oerp-edu.aden.org/web/image?model=repo.aden.entrega.alumno&field=video_correccion&id=${idTask}`}
                        ></video>
                        <ModalButton variant="outlined" onClick={() => setOpenModalVideo(false)}>
                            Salir
                        </ModalButton>
                    </ContentVideo>
                </FakeDialog>
            <Container>
                <Text
                    fontSize="16px"
                    color="#222222"
                    fontWeight="bold"
                >
                    Mi comentario sobre esta entrega:
                </Text>
                <CommentArea>
                    <p>
                        {
                            valuesCorrection.comment_teacher || "No posee comentarios del profesor"
                        }
                    </p>
                </CommentArea>
                <Text
                    fontSize="16px"
                    color="#222222"
                    fontWeight="bold"
                >
                    Mis adjuntos:
                </Text>
                <FilesArea>
                    {/* Hacer que se pongan en columna solo si vienen los tres, si vienen 2 o 1 creo que no hace falta */}
                    {
                        !teacherFiles?.correction_file && !teacherFiles?.audio_file && !teacherFiles?.video_file &&
                        <p>No hay archivos adjuntos de esta entrega</p>
                    }
                    {
                        teacherFiles?.correction_file &&
                        <FileButton onClick={handleShowFile}>
                            <Icon icon="ic:round-attach-file" width="24px" height="24px" />
                            <label>Archivo</label>
                        </FileButton>
                    }
                    {
                        teacherFiles?.audio_file &&
                        <FileButton onClick={handleShowVoiceNote}>
                            <Icon icon="f7:mic" width="24px" height="24px" />
                            <label>Nota de voz</label>
                        </FileButton>
                    }
                    {
                        teacherFiles?.video_file &&
                        <FileButton onClick={handleShowVideo}>
                            <Icon icon="basil:video-outline" width="24px" height="24px" />
                            <label>Ver video</label>
                        </FileButton>
                    }
                </FilesArea>
                <Text
                    fontSize="16px"
                    color="#222222"
                    fontWeight="bold"
                >
                    Comentario del estudiante sobre esta entrega:
                </Text>
                <CommentArea>
                    <p>
                        {
                            studentComment || "No posee comentarios del alumno"
                        }
                    </p>
                </CommentArea>
            </Container>
        </>
    )
}

export default InfoOldSection;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const CommentArea = styled.div`
    height: fit-content;
    padding: 26px 28px;
    border-radius: 10px;
    border: 1px solid #C4C4C4;

    p {
        color: #000;
    }
`;

const FilesArea = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: start;

    @media (width < 1575px) {
        flex-direction: column;
        align-items: start;
    }
`

const FileButton = styled.button`
    cursor: pointer;
    max-height: 44px;
    padding: 12px 24px 12px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #B31D15;
    color: #B31D15;
    transition: all 0.3s ease-in-out;

    svg {
        min-height: 24px;
        min-width: 24px;
    }

    label {
        cursor: pointer;
        font-weight: 600;
    }

    :hover {
        color: #fff;
        background-color: #B31D15;
    }
`

const FakeDialog = styled(Dialog)`
    & > div {
        max-width: 100vw;
    }
    & > div:nth-child(3) {
        width: 100vw;
        max-height: 100%;

        & > div {
            max-width: fit-content;
            border-radius: 20px;
        }
    }

    .camera {
        width: calc(100% - 4rem);
    }
`

const ContentVideo = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    border-radius: 20px;
`;

const ModalButton = styled(Button)`
    min-width: 100px;
    border-radius: 100px;
`