import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Card, Button } from '@mui/material';

import { checkRoles } from '../../../../helpers/roles.helper';
import useCourses from '../../../../hooks/common/useCourses';

import GenericSlider from '../../../../components/common/GenericSlider';
import CampusSubjectCard from '../../../../components/ui/molecules/campus/CampusSubjectCard';
import { Text } from '../../../../components/common/Texts';
import SubjectsCard from '../../../../components/common/cards/SubjectsCard';
import Slider from '../../../../components/common/Slider';
import LazyImg from '../../../../components/common/LazyImg';
import NoCoursesFound from '../../../../assets/media/campus/no-course_found.png';

const SubjectsSlider = ({ title = 'Asignaturas' }) => {
    const { allCourses } = useCourses();

    const [subjects, setSubjects] = useState(null);

    useEffect(() => {
        if (allCourses?.list) {
            setSubjects(
                allCourses?.list?.filter(
                    (course) =>
                        course?.estado === 'Confirmado' &&
                        course?.progreso < 100 &&
                        course?.type !== 'asignatura'
                )
            );
        }
    }, [allCourses]);

    if (!subjects) {
        return <Skeleton h="580px" w="100%" />;
    }

    return (
        <Wrapper>
            <SliderTitle fontSize="20px" fontWeight="700" color="#B31D15">
                {title}
            </SliderTitle>
            {subjects?.length > 0 && (
                <>
                    {checkRoles(['alumno', 'Admin']) && (
                        <CardFake>
                            {subjects?.length <= 4 && (
                                <NotSliderWrapper>
                                    <ButtonWrapper>
                                        <Link to={'/mi-progreso'}>
                                            <RedirectButton
                                                variant="text"
                                                color="tertiary"
                                            >
                                                Ver más
                                            </RedirectButton>
                                        </Link>
                                    </ButtonWrapper>
                                    <Content>
                                        <CardsWrapper>
                                            {subjects?.map((subject, index) => (
                                                <CampusSubjectCard
                                                    key={index}
                                                    index={index}
                                                    value={subject}
                                                    slider={false}
                                                />
                                            ))}
                                        </CardsWrapper>
                                        <FillImageWrapper>
                                            <LazyImg
                                                src={NoCoursesFound}
                                                width="320px"
                                                height="fit-content"
                                                backgroundColor="#ffffff"
                                            />
                                        </FillImageWrapper>
                                    </Content>
                                </NotSliderWrapper>
                            )}

                            {subjects?.length > 4 && (
                                <Slider
                                    array={subjects}
                                    card={CampusSubjectCard}
                                    to={'/mi-progreso'}
                                    config={{
                                        type: 'carousel',
                                        startAt: 0,
                                        perView: 4,
                                        gap: 32,
                                    }}
                                />
                            )}
                        </CardFake>
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default SubjectsSlider;

const Wrapper = styled.div``;

const CardFake = styled(Card)`
    position: relative;
    width: calc(100% - 48px);
    box-shadow: var(--shadow-strong);
    border-radius: var(--border-radius_sharp);
    background-color: #fff;
    padding: 32px 24px;
`;

const Skeleton = styled.div`
    background-color: #ededed;
    border-radius: 20px;
    height: ${(p) => p.h};
    width: ${(p) => p.w};
`;

const SliderTitle = styled(Text)`
    padding: 1rem 1.5rem 1.4rem 1.5rem;
`;

const RedirectButton = styled(Button)`
    width: 120px;
    border-radius: var(--border-radius_rounded);
`;

const NotSliderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const CardsWrapper = styled.div`
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Content = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

const FillImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        display: none;
    }
`;
