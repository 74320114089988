import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Material UI
import {
    ThumbDownRounded,
    ThumbUpRounded,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { utilsActions } from '../../../../redux/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NavegationBottom = (props) => {
    const {
        currentPosition,
        menuItems,
        nextItem,
        backItem,
        idCurrentMedia,
        blockResocurce,
        format,
        visto = false,
    } = props;

    const dispatch = useDispatch();
    const likes = useSelector((state) => state.utils.likes);
    const dislikes = useSelector((state) => state.utils.dislikes);
    const likeType = useSelector((state) => state.utils.likeType);
    const calificacion = useSelector((state) => state.utils.calificacion);

    // STATE
    const [likeDislike, setLikeDislike] = useState(null);
    const [current, setCurrent] = useState(0);

    // EFFECTS
    useEffect(() => {
        dispatch(
            utilsActions.getCalificacionRequest({
                type: 'recurso',
                id: idCurrentMedia,
            })
        );
        dispatch(
            utilsActions.getLikesRequest({
                type: 'recurso',
                id: idCurrentMedia,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idCurrentMedia]);

    useEffect(() => {
        if (calificacion === '10') {
            setLikeDislike(true);
        } else if (calificacion === '1') {
            setLikeDislike(false);
        } else {
            setLikeDislike(null);
        }
    }, [calificacion, idCurrentMedia]);

    useEffect(() => {
        function beforeUnloadFn() {
            if (likeType !== null) {
                dispatch(
                    utilsActions.resourceRequestLikes({
                        id: idCurrentMedia,
                        type: likeType,
                    })
                );
            }
        }
        window.addEventListener('beforeunload', beforeUnloadFn);
        return () => {
            beforeUnloadFn();
            window.removeEventListener('beforeunload', beforeUnloadFn);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getPosition(currentPosition, menuItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPosition]);

    // FUNCTIONS
    function handleLike() {
        setLikeDislike(true);
        dispatch(utilsActions.setLike('like'));
    }

    function handleDislike() {
        setLikeDislike(false);
        dispatch(utilsActions.setDislike('dislike'));
    }

    const getPosition = (current, array) => {
        let result = 0;
        array.forEach((item, i) => {
            if (item.nombre_publicacion === current.nombre_publicacion) {
                result = i;
            }
        });
        setCurrent(result);
    };

    return (
        <NavegationBottomContainer>
            <ButtonsWrapper>
                <ButtonOption
                    color="primary"
                    disabled={current === 0 || blockResocurce === true}
                    onClick={backItem}
                    startIcon={<ArrowBackIcon />}
                >
                    Anterior
                </ButtonOption>
            </ButtonsWrapper>
            <ButtonAndRating>
                {calificacion && (
                    <RatingWrapper>
                        <FakeButton
                            onClick={handleLike}
                            disabled={likeDislike === true}
                            selected={likeDislike === true}
                        >
                            <ThumbUpRounded color="secondary" />
                            <span>{likes}</span>
                        </FakeButton>
                        <FakeButton
                            onClick={handleDislike}
                            disabled={likeDislike === false}
                            selected={likeDislike === false}
                        >
                            <ThumbDownRounded color="secondary" />
                            <span>{dislikes}</span>
                        </FakeButton>
                    </RatingWrapper>
                )}
            </ButtonAndRating>
            <ButtonsWrapper>
                <ButtonOption
                    color="primary"
                    disabled={
                        current === menuItems.length - 1 ||
                        blockResocurce === true ||
                        !visto
                    }
                    onClick={nextItem}
                    endIcon={<ArrowForwardIcon />}
                >
                    Siguiente
                </ButtonOption>
            </ButtonsWrapper>
        </NavegationBottomContainer>
    );
};

export default NavegationBottom;

const NavegationBottomContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 40px;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px #0000003b, 0px 3px 6px 0px #00000029;
    width: calc(100% - 2rem);
    border-radius: 30px;

    @media (max-width: 768px) {
        background-color: #f1f1f1;
    }
`;

const ButtonAndRating = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2rem;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
    }
`;

const RatingWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const FakeButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    span {
        font-size: 15px;
    }

    svg,
    span {
        color: ${(props) => (props.selected ? '#b31d15' : '#616161')};
    }

    :hover {
        svg {
            color: #b31d15;
        }
        span {
            color: #b31d15;
        }
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ButtonOption = styled(Button)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;
