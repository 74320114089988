import axios from "axios";

export async function getAnnouncementsByRepoId(repo_id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/notifications/user_notification_newsletters/${repo_id}`
    try {
        const response = await axios.get(URL);
        return response;
    } catch (error) {
        return { error };
    }
}

export async function getAnnouncementsByAssignmentId(assignment_id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/notifications/asignatura_notification_newsletters/${assignment_id}`
    try {
        const response = await axios.get(URL);
        return response;
    } catch (error) {
        return { error };
    }
}

export async function postAnnouncements(repo_id, body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/notifications/create_notif_newsletter_as_professor/${repo_id}`
    try {
        const response = await axios.post(URL, body);
        return response;
    } catch (error) {
        return { error };
    }
}