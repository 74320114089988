import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAnnouncementsByRepoId } from "../../redux/api/announcements";
import AnnouncementSkeleton from "../../components/ui/organisms/subject/announcements/AnnouncementSkeleton";
import NotFoundImg from "../../assets/media/aden/not_found.png"
import Announcements from "../../components/ui/organisms/subject/announcements/Announcements";
import Pagination from "../../components/ui/organisms/subject/announcements/Pagination";
import useLayout from "../../hooks/useLayout";

const GeneralAnnouncements = (props) => {

    const { professor } = props;

    const [announcementsData, setAnnouncementsData] = useState(false)
    const [initialValues, setInitialValues] = useState(false)
    const [page, setPage] = useState(1);
    const pageMax = Math.ceil(announcementsData?.length / 4);

    const { repo_id } = useSelector((state) => state.auth.user);

    const { setMenu } = useLayout();

    useEffect(() => {
        if (!announcementsData) {
            getAnnouncements()
        }
        if (!professor) {
            setMenu("Anuncios")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementsData])

    const getAnnouncements = async () => {
        const response = await getAnnouncementsByRepoId(repo_id);
        if (!response.error) {
            setAnnouncementsData(response.data)
            setInitialValues(response.data)
        } else {
            console.error(response.error)
        }
    }

    if (!initialValues) {
        return (
            <Wrapper>
                <AnnouncementSkeleton />
            </Wrapper>
        )
    }

    if (initialValues.length === 0 || initialValues === undefined) {
        return (
            <WrapperNotFound>
                <img src={NotFoundImg} alt="not-found" />
                <p>
                    Aún no tienes anuncios en <br /> tu bandeja de entrada
                </p>
            </WrapperNotFound>
        )
    }

    return (
        <Wrapper>
            <h1>Anuncios generales por asignaturas:</h1>
            <Announcements
                initialValues={initialValues}
                announcementsData={Array.isArray(announcementsData) ? announcementsData.slice((page * 4 - 4), page * 4) : []}
                setAnnouncementsData={setAnnouncementsData}
                professor={professor}
                general
            />
            <Pagination page={page} setPage={setPage} pageMax={pageMax} />
        </Wrapper>
    )
}

export default GeneralAnnouncements;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

    h1 {
        color: #616161;
        font-size: 20px;
        font-weight: 500;
    }
`

const WrapperNotFound = styled.div`
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 80vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
        text-align: center;
    }
`