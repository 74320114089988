import React from "react";
import { IconButton } from "@mui/material";
import ReloadIcon from "@mui/icons-material/Cached";
import Popover from "@mui/material/Popover";
import styled from "styled-components";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import { Link } from "react-router-dom";
import { useState } from "react";
import useTheme from "../../../hooks/useTheme";
import { Icon } from "@iconify/react";
import AnnouncementCardNotification from "./AnnouncementCardNotification";

const AnnouncementsNotification = (props) => {
    const { announcements, refreshAnnouncements } = props;

    // STATES
    const [anchorEl, setAnchorEl] = useState(null);
    const { primary } = useTheme();

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // FUNCTIONS
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleGoTo = () => {
        window.location.href = "/anuncios";
    };

    const countNewAnnouncements = () => {
        let count = 0;
        if (announcements) {
            announcements.forEach((newsletter) => {
                newsletter.newsletters.forEach((item) => {
                    if (new Date() >= new Date(item?.published_date) && new Date(item?.expiration_date) >= new Date()) {
                        count++
                    }
                })
            })
        }
        return count;
    }

    return (
        <div>
            <IconButton
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={handleClick}
                size="large">
                <Icon icon="foundation:megaphone" width="1em" height="1em" style={{ color: '#bfbfbf' }}
                />
                {
                    (announcements?.length !== 0 && countNewAnnouncements() !== 0) &&
                    <Count color={primary}>
                        {countNewAnnouncements()}
                    </Count>
                }
            </IconButton>
            <PopoverFake
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <HeaderNotifications color={primary}>
                    <Title>Anuncios</Title>
                    <Buttons>
                        <ButtonReload onClick={refreshAnnouncements}>
                            <RefreshIcon color="white" />
                        </ButtonReload>
                        <Link onClick={handleClose} to="/anuncios">
                            <OpenNotifications />
                        </Link>
                    </Buttons>
                </HeaderNotifications>
                <BodyNotification>
                    {announcements === undefined || announcements?.length === 0 ?
                        /* Arreglar esto */
                        <NotFound>
                            <Icon icon="heroicons-outline:refresh" width="1.2em" height="1.2em" />
                            <h3>No encontramos anuncios</h3>
                            <button onClick={refreshAnnouncements}>Actualizar</button>
                        </NotFound>
                        : <>
                            {
                                announcements.map((announcement, i) => {
                                    return (
                                        <>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                announcement.newsletters.map((newsletter, i) => {
                                                    if (new Date() >= new Date(newsletter?.published_date)) {
                                                        return (
                                                            <AnnouncementCardNotification
                                                                subjectName={announcement?.asignatura_name}
                                                                announcement={newsletter}
                                                                index={i}
                                                                handleGoTo={handleGoTo}
                                                            />
                                                        )
                                                    }
                                                })
                                            }
                                        </>
                                    )
                                })
                            }
                        </>}
                </BodyNotification>
            </PopoverFake>
        </div>
    );
};

export default AnnouncementsNotification;

const PopoverFake = styled(Popover)`
    .MuiPaper-root {
        border-radius: 20px;
        background-color: #f9f9f9;
        @media (max-width: 768px) {
            width: 100vw;
        }
    }
`;

const Count = styled.h1`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1rem;
    height: 1rem;
    font-size: 0.7rem;
    color: white;
    background-color: ${(p) => p.color};
    position: absolute;
    border-radius: 500px;
    top: 0.3rem;
    right: 0.3rem;
`;

const HeaderNotifications = styled.div`
    height: 2.8rem;
    width: 20rem;
    background-color: ${(p) => p.color};
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;

    @media (max-width: 768px) {
        width: calc(100% - 24px);
    }
`;

const Title = styled.h6`
    color: white;
    font-size: 1.2rem;
`;

const OpenNotifications = styled(OpenInNewSharpIcon)`
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
`;

const RefreshIcon = styled(ReloadIcon)`
    cursor: pointer;
    color: white;
    font-size: 1.35rem;
    display: flex;
    align-items: center;
`;

const BodyNotification = styled.div`
    width: 100%;
    height: 25rem;
    overflow-y: auto;
    padding: 0.3rem 0.6rem;
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
`;

const NotFound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

    svg {
        width: 32px;
        height: 32px;
        color: #A8A8A8;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #616161;
    }

    button {
        cursor: pointer;
        height: 40px;
        padding: 0 1rem;
        background-color: #b31d15;
        color: white;
        border-radius: 8px;

        :hover {
            background-color: #cd2118;
        }
    }
`

const ButtonReload = styled.button`
    border: none;
    outline: none;
    padding: 0;
    background: none;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
