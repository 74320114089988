import React from 'react';
import styled from 'styled-components';
import { Img } from '../../common/Image';
import Logo from '../../../assets/media/aden/logo-aden-simple-red.png';
import Text from '../../common/Text';
import AuthComponent from '../../ui/organisms/auth/AuthComponent';

const AuthForm = () => {
    return (
        <AuthFormWrapper>
            <Img w="200px" src={Logo} />
            <AuthComponent />
            <TextFake fontWeight="500" textColor="#999999" fontSize="1rem">
                © {new Date().getFullYear()} ADEN. Todos los derechos reservados
            </TextFake>
        </AuthFormWrapper>
    );
};

export default AuthForm;

const AuthFormWrapper = styled.div`
    padding: 3rem 8rem;
    text-align: center;
    width: calc(100% - 16rem);
    height: calc(100% - 6.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    @media (max-width: 768px) {
        padding: 3px;
        width: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }
`;

const TextFake = styled(Text)`
    @media (max-width: 768px) {
        position: fixed;
        bottom: 1rem;
    }
`;
