import styled from "styled-components";
import Announcements from "./announcements/Announcements";
import Pagination from "./announcements/Pagination";
import { useEffect, useState } from "react";
import AnnouncementSkeleton from "./announcements/AnnouncementSkeleton";
import { Icon } from "@iconify/react";
import { getAnnouncementsByAssignmentId, postAnnouncements } from "../../../../redux/api/announcements";
import NotFoundImg from "../../../../assets/media/aden/not_found.png"
import { announcementSchema } from "../../../../utils/schemas";
import { useSelector } from "react-redux";
import PopUpSuccess from "./announcements/PopUpSuccess";
import ModalCreateAnnouncement from "./announcements/ModalCreateAnnouncement";

const SubjectAnnouncements = (props) => {

    // eslint-disable-next-line no-unused-vars
    const { id, professor } = props;

    const [announcementsData, setAnnouncementsData] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)
    const pageMax = Math.ceil(announcementsData.length / 4);

    const { repo_id } = useSelector((state) => state.auth.user);

    const initialValues = {
        title: "",
        message: "",
        published_date: "",
        expiration_date: "",
        asignatura_id: id
    }

    useEffect(() => {
        if (!announcementsData) {
            getAnnouncements()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementsData])

    const getAnnouncements = async () => {
        const response = await getAnnouncementsByAssignmentId(id);
        if (!response.error) {
            setAnnouncementsData(response.data)
        } else {
            console.error(response.error)
        }
    }

    const handleSubmitAnnouncement = async (values) => {
        const today = new Date();
        const publishedDate = new Date(values.published_date + " 23:59:00");
        publishedDate.setDate(publishedDate.getDate())
        const expirationDate = new Date(values.expiration_date + " 23:59:00");
        expirationDate.setDate(expirationDate.getDate())

        if (today <= publishedDate) {
            if (publishedDate < expirationDate) {
                setLoading(true)
                try {
                    await postAnnouncements(repo_id, values)
                    setLoading(false)
                    setOpenModal(false)
                    setOpenModalSuccess(true)
                } catch (error) {
                    setLoading(false)
                    setError("Error, por favor intente de nuevo")
                }
            } else {
                setError("La fecha de caducidad debe ser posterior a la fecha de publicación")
            }
        } else {
            setError("La fecha de publicación debe ser igual o posterior a la fecha actual")
        }

    }

    if (!announcementsData) {
        return (
            <Wrapper>
                <AnnouncementSkeleton />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            {
                professor ? <>
                    <h1>Crea un anuncio y mantén a tus alumnos informados</h1>
                    <Button onClick={() => setOpenModal(true)}>
                        <Icon icon="foundation:megaphone" width="1.5em" height="1.5em" style={{ color: '#fff' }} />
                        Crear nuevo anuncio
                    </Button>
                </>
                    : <h1>Anuncios correspondientes a esta asignatura:</h1>
            }
            {
                openModalSuccess && <PopUpSuccess setOpenModal={setOpenModalSuccess} />
            }
            {
                openModal &&
                <ModalCreateAnnouncement 
                    initialValues={initialValues} 
                    handleSubmitAnnouncement={handleSubmitAnnouncement} 
                    announcementSchema={announcementSchema} 
                    setOpenModal={setOpenModal} 
                    loading={loading}
                    error={error}
                />
            }
            {
                announcementsData.length === 0 ?
                    <WrapperNotFound>
                        <img src={NotFoundImg} alt="not-found" />
                        <p>
                            Aún no tienes anuncios en <br /> tu bandeja de entrada
                        </p>
                    </WrapperNotFound>
                    :
                    <>
                        <Announcements
                            initialValues={announcementsData}
                            announcementsData={Array.isArray(announcementsData) ? announcementsData.slice((page * 4 - 4), page * 4) : []}
                            setAnnouncementsData={setAnnouncementsData}
                            professor={professor}
                        />
                        <Pagination page={page} setPage={setPage} pageMax={pageMax} />
                    </>
            }
        </Wrapper>
    )
}

export default SubjectAnnouncements;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;

    h1 {
        color: #616161;
        font-size: 20px;
        font-weight: 500;
    }
`

const WrapperNotFound = styled.div`
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 80vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 24px;
        font-weight: 700;
        color: #b31d15;
        text-align: center;
    }
`

const Button = styled.button`
    cursor: pointer;
    border-radius: 5px;
    margin-top: 2rem;
    margin-left: 2rem;
    background-color: #b31d15;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    :hover {
        background-color: #CD2118
    }
`