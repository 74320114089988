import React, { useEffect, useState } from "react";
import styled from "styled-components";

import GridResource from "../../components/common/GridResource";
import MicrolearningsCard from "../../components/ui/molecules/microlearnings/MicrolearningsCard";
import { useDispatch, useSelector } from "react-redux";
import { microlearningsActions } from "../../redux/actions";
import SharedResource from "../../components/common/SharedResource";
// import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import MicrocontentCardSkeleton from "../../components/common/MicrocontentCardSkeleton";
import EmptyGridResource from "../../components/common/EmptyGridResource";
import useTheme from "../../hooks/useTheme";
import TabsComponent from "../../components/common/TabsComponent";
import { titleHTML } from "../../helpers/title.helper";
import MainSimulator from "../../modules/simulatorModule/components/MainSimulator";
import { useQueryParams } from "../../hooks/useQueryParams";

const Content = () => {
    titleHTML("Biblioteca");

    // REDUX
    const dispatch = useDispatch();
    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );
    const listLoading = useSelector(
        (state) => state.microlearnings.listLoading
    );
    const { primary } = useTheme();

    const queryTab = useQueryParams();
    const initialTab = queryTab.get("tab") === "simulador" ? 1 : 0;

    // STATE
    const [tab, setTab] = useState(initialTab);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);

    const emptyItems = new Array(6).fill(null);

    // EFFECTS
    useEffect(() => {
        if (tab === 0 && microlearnings === null) {
            dispatch(microlearningsActions.getMicrolearningsRequest());
        }

        if (microlearnings != null && microlearnings.length > 0) {
            microlearnings.filter(
                (ml) =>
                    ml.fecha_hasta === "False" ||
                    !ml.fecha_hasta ||
                    new Date(ml.fecha_hasta) > new Date()
            );
        }
    }, [microlearnings]);

    // FUNCTIONS
    function handleTab(_, value) {
        setTab(value);
    }

    function handleOpenModal(id, nombre_publicacion) {
        setModalIsOpen(true);
        setSharedItem({
            lp_type: "microcontent",
            type: "recurso",
            id,
            nombre_publicacion,
        });
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    const renderCase = (tab) => {
        const caseType = {
            0:
                microlearnings === null || listLoading ? (
                    <EmptyGridResource
                        propsValues={emptyItems}
                        Card={MicrocontentCardSkeleton}
                    />
                ) : (
                    <GridResource
                        propsValues={microlearnings}
                        type={"micro"}
                        Card={MicrolearningsCard}
                        handleOpenModal={handleOpenModal}
                    />
                ),
            1: <MainSimulator />,
        };

        return caseType[tab];
    };

    const tabs = [
        { label: "Microlearning", id: 0 },
        { label: "Simulador 360", id: 1 },
    ];
    // RETURN
    return (
        <ContentContainer>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedItem={sharedItem}
            />
            <Title color={primary}>Biblioteca de contenido</Title>
            <ContentMain>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />

                {microlearnings === null || listLoading ? (
                    <GridMain>
                        <EmptyGridResource
                            propsValues={emptyItems}
                            Card={MicrocontentCardSkeleton}
                        />
                    </GridMain>
                ) : (
                    <GridMain>{renderCase(tab)}</GridMain>
                )}
            </ContentMain>
        </ContentContainer>
    );
};

export default Content;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const Title = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    color: ${(p) => p.color};
`;

const ContentMain = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;
