import React, { useEffect, useState } from "react";
import { Route, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimpleLoading from "../components/common/SimpleLoading";
import { authActions } from "../redux/actions";
import { getHomeByRol } from "../helpers/roles.helper";
import { useQueryParams } from "../hooks/useQueryParams";

const AutoLoginRoute = (props) => {
    const [flag, setFlag] = useState(false);

    const dispatch = useDispatch();
    const { authToken, loadingGetUser, activeRol, user, error } = useSelector(
        (state) => state?.auth
    );


    const params = useQueryParams();
    const redirecturi = params.get("redirecturi");
    const rol = params.get("rol");

    const DEFAULT_ROL = !!rol ? rol : "visita";


    const token = params.get("token");

    const { push } = useHistory();

    const handleLogin = () => {
        if (!loadingGetUser && activeRol !== DEFAULT_ROL) {
            dispatch(authActions.getDataRequest(DEFAULT_ROL));
        }
        if (!loadingGetUser && activeRol === DEFAULT_ROL) {
            push(getHomeByRol(DEFAULT_ROL));
        }
    };

    // EFFECTS
    useEffect(() => {
        if (!!authToken && !!user) {
            push(`../${redirecturi}`);
        }
        if (!authToken && !user) {
            dispatch(authActions.setTokenAuth(token));
        }

        if (!!authToken && !user) {
            if (!flag) {
                handleLogin();
                setFlag(true);
            } else if (!!error) {
                dispatch(authActions.setTokenAuth(null));
                push(`../auth?redirect=${redirecturi}`);
            }
        }
    }, [loadingGetUser, authToken]);

    return (
        <>
            <Route
                {...props}
                render={() => {
                    return <SimpleLoading />;
                }}
            />
        </>
    );
};

export default AutoLoginRoute;
