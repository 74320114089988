import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { checkRoles } from '../../../helpers/roles.helper';

import TabsComponent from '../../common/TabsComponent';
import Achievements from '../../ui/organisms/profile/Achievements';
import InterestsMotivations from '../../ui/organisms/profile/InterestsMotivations';
// import Programs from '../../ui/organisms/profile/Programs';
import TrainingExperience from '../../ui/organisms/profile/TrainingExperience';

const tabs = [
    { label: 'Intereses y motivaciones', id: 0 },
    // { label: 'Programas', id: 1 },
    { label: 'Logros', id: 2 },
    { label: 'Formación y Experiencia', id: 3 }
];

const tabsVisita = [
    { label: 'Intereses y motivaciones', id: 0 },
    { label: 'Formación y Experiencia', id: 3 }
];

const MainProfile = () => {
    // STATE
    const [tab, setTab] = useState(0);
    const { user } = useSelector((state) => state.auth);

    // FUNCTIONS
    function handleTab(e, value) {
        setTab(value);
    }

    // RETURN
    return (
        <MainProfileContainer>
            {!checkRoles('visita') ? (
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            ) : (
                <TabsComponent
                    tab={tab}
                    handleTab={handleTab}
                    tabs={tabsVisita}
                />
            )}
            <BodyWrapper>
                <ToggleMain tab={tab} user={user} />
            </BodyWrapper>
        </MainProfileContainer>
    );
};

export default MainProfile;

const MainProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const BodyWrapper = styled.div`
    padding: 2rem;
`;

function ToggleMain(props) {
    const { tab, user } = props;
    switch (tab) {
        case 0:
            return <InterestsMotivations />;
        // case 1:
        //     return <Programs />;
        case 2:
            return <Achievements />;
        case 3:
            return <TrainingExperience user={user} />;
        default:
            return null;
    }
}
