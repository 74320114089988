import React from 'react';

import OptionOne from '../../organisms/profile/OptionOne';
import OptionTwo from '../../organisms/profile/OptionTwo';
import OptionThree from '../../organisms/profile/OptionThree';
import OptionFour from '../../organisms/profile/OptionFour';
import OptionFive from '../../organisms/profile/OptionFive';

const OptionModalEditProfile = (props) => {
    const { currentTab, values, setFieldValue } = props;

    // RETURN
    if (currentTab === 0) {
        return <OptionOne values={values} setFieldValue={setFieldValue} />;
    }

    if (currentTab === 1) {
        return <OptionTwo values={values} setFieldValue={setFieldValue} />;
    }

    if (currentTab === 2) {
        return <OptionThree values={values} setFieldValue={setFieldValue} />;
    }

    if (currentTab === 3) {
        return <OptionFour values={values} setFieldValue={setFieldValue} />;
    }

    if (currentTab === 4) {
        return <OptionFive values={values} setFieldValue={setFieldValue} />;
    }

    return null;
};

export default OptionModalEditProfile;
