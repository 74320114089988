import React from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@iconify/react/dist/iconify.js";

const MultiElectivesSelection = (props) => {
    const {
        electives,
        multiSelectedItems,
        optionCheck,
        handleMultiSelectionChange,
        electivesFormated,
    } = props;

    return (
        <CustomFormGroup>
            {electives.map((elective) => (
                <CustomFormControlLabel key={elective.id}>
                    <ContainerInfo>
                        <span>
                            <label>{elective.publication_name}</label>
                            <p>{elective?.description}</p>
                        </span>
                        <Checkbox
                            type="checkbox"
                            disabled={
                                electivesFormated.length > 0
                                    ? electivesFormated.some(
                                        (id) => id.id === elective.id
                                    )
                                    : optionCheck === elective.id
                            }
                            checked={multiSelectedItems.some(
                                (selectedItem) => selectedItem.id === elective.id
                            )}
                            onChange={() =>
                                handleMultiSelectionChange(
                                    elective.id,
                                    elective.publication_name,
                                    elective?.course_in_person
                                )
                            }
                        />
                    </ContainerInfo>
                    {
                        elective?.course_in_person && (
                            <InfoContainer>
                                <Icon icon="octicon:info-24" width="24px" height="24px" />
                                <label>
                                    Esta opción sólo está disponible para aquellos que <b>asistieron a la clase presencial.</b>
                                </label>
                            </InfoContainer>
                        )
                    }
                </CustomFormControlLabel>
            ))}
        </CustomFormGroup>
    );
};

export default MultiElectivesSelection;

const CustomFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;

const CustomFormControlLabel = styled.div`
    border: solid 1px #dcdcdc;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 10px;

    input {
        margin-right: 0.5rem;
    }

    span {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    p {
        font-size: 0.9rem;
        font-weight: 500;
        text-align: start;
        padding-right: 8px;
    }

    label {
        font-size: 0.9rem;
        text-align: start;
        font-weight: 700;
    }
`;

const ContainerInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 2rem);
    gap: 0.5rem;
`

const InfoContainer = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;
    margin: 1rem 0;

    svg {
        color: #C29F43;
    }
`