import styled from "styled-components";
import ProfileImage from "../../../../../assets/media/aden/profile-img-test.jpg";
import parse from 'html-react-parser';
import { toTitleCase } from "../../../../../helpers/titleCase.helper";

const AnnouncementCard = (props) => {

    const { subjectName, card, setOpenModal, isOpen, isGeneral = false, general } = props;

    const today = new Date()

    const announcementIsNew = today >= new Date(card?.published_date) && new Date(card?.expiration_date) >= today; 

    return (
        <Container seen={announcementIsNew} isOpen={isOpen}>
            <Header>
                <AvatarImage alt="" src={card?.newsletter_creator?.image ?? ProfileImage} />
                <HeaderInfo seen={announcementIsNew} isGeneral={isGeneral}>
                    {
                        (general) &&
                        <>
                            <Title>
                                ASIGNATURA: {subjectName?.toUpperCase()}
                            </Title>
                            <Line />
                        </>
                    }
                    <HeaderSubInfo>
                        <label>
                            {card?.title}
                        </label>
                        <span>
                            {card?.published_date}
                        </span>
                    </HeaderSubInfo>
                    <ProfessorInfo>
                        <span>{card?.newsletter_creator?.name}</span>
                        <span>|</span>
                        <span>{toTitleCase(card?.newsletter_creator?.newsletter_creator_role)}</span>
                    </ProfessorInfo>
                </HeaderInfo>
            </Header>
            {
                isOpen
                ? <p>
                    {parse(card?.message)}
                </p>
                : <p>
                    {card?.message}
                </p>
            }
            {
                !isOpen &&
                <button onClick={() => setOpenModal(true)}>Ver anuncio completo.</button>
            }
        </Container>

    )
}

export default AnnouncementCard;

const Container = styled.div`
    width: 100%;
    height: ${(props) => props.isOpen ? "fit-content" : "170px"};
    box-shadow: 0px 8px 8px -4px #18274B14, 0px 4px 6px -4px #18274B1F;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    border-left: 8px solid ${(props) => !props.seen ? "#F1F1F1" : "#b31d15"};
    background-color: #fff;

    p {
        padding: 0 ${(props) => props.isOpen ? "0.5rem" : "1rem"};
        ${(props) => !props.isOpen ?
        `display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;`
        :
        `margin-bottom: 1rem;`
    }
        font-weight: 400;
    }

    button {
        cursor: pointer;
        color: ${(props) => !props.seen ? "#616161" : "#b31d15"};
        font-size: 16px;
        font-weight: 600;
        margin-left: 1rem;
    }
`

const Header = styled.div`
    display: flex;
    gap: 1rem;
    padding: 1rem;
`

const AvatarImage = styled.div`
    background-image: url(${(props) => props.src});
    width: 64px;
    height: 64px;
    background-size: cover;
    background-position: center center;
    border-radius: 100px;
`

// ARREGLAR ACÁ

const HeaderInfo = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => !props.isGeneral ? "center" : props.seen ? "space-around" : "space-between"};
    color: ${(props) => props.seen ? "#a8a8a8" : "#222222"};
    gap: 4px;
`

const Title = styled.h2`
    font-size: 12px;
    font-weight: 400;
`

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #222;
`

const HeaderSubInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        font-size: 15px;
        font-weight: 700;
    }

    span {
        font-size: 12px;
        font-weight: 700;
    }
`

const ProfessorInfo = styled.div`
    display: flex;
    gap: 0.5rem;

    span {
        font-size: 12px;
        font-weight: 700;
    }
`