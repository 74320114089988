import React, { useEffect } from "react";
import styled from "styled-components";
import TabsComponent from "../../../common/TabsComponent";
import { useState } from "react";
import LoginForm from "../../molecules/auth/LoginForm";
import RecoverForm from "../../molecules/auth/RecoverForm";
import LoginFormLab from "../../molecules/auth/LoginFormLab";
import RegisterForm from "../../molecules/auth/RegisterForm";
import { useQueryParams } from "../../../../hooks/useQueryParams";

const AuthComponent = (props) => {
  const { isLab, primaryColor } = props;

  const params = useQueryParams();
  let tabs = [{ label: "Iniciar sesión", id: 0 }];
  let registration = false;

  // STATES
  const [tab, setTab] = useState(params.get("registration") ? 1 : 0);

  // Si es AcropolisLab no tiene la opcion de registro
  if (!isLab) {
    tabs = [
      { label: "Iniciar sesión", id: 0 },
      { label: "Registrarme", id: 1 },
    ];
    registration = true;
  }

  // Si se ingresa al path recover y tiene id se
  // redirecciona al reestablecimiento de la contraseña
  useEffect(() => {
    window.location.href.includes("/recover") && setTab(2);
  }, []);

  function handleTabs(_, value) {
    setTab(value);
  }

  function handleTabsComp(value) {
    setTab(value);
  }

  return (
    <AuthComponentWrapper>
      {!isLab && tab !== 2 ? (
        <TabsComponent
          tab={tab}
          center={true}
          handleTab={handleTabs}
          tabs={tabs}
        />
      ) : null}
      {!isLab ? (
        <>
          {tab === 0 && (
            <LoginForm registration={registration} handleTab={handleTabsComp} />
          )}
        </>
      ) : (
        <>{tab === 0 && <LoginFormLab primaryColor={primaryColor} />}</>
      )}

      {tab === 1 && <RegisterForm handleTab={handleTabsComp} />}
      {tab === 2 && <RecoverForm handleTab={handleTabsComp} />}
    </AuthComponentWrapper>
  );
};

export default AuthComponent;

const AuthComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
