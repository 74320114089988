import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import UserConversation from './UserConversation';

const BodyAside = (props) => {
    // Props
    const { channels, searchChannel, aside, handleButton } = props;

    //State Components
    const [selectChannel, setSelectChannel] = useState({
        partner_id: null
    });

    // Use Effect
    useEffect(() => {}, [channels]);

    const handleSetSelectChannel = (partner_id) => {
        setSelectChannel({ partner_id });
        handleButton();
    };

    return (
        <Container>
            <List name="data" aside={aside}>
                {searchChannel &&
                    searchChannel.length > 0 &&
                    searchChannel
                        .sort((a, b) => b.fecha - a.fecha)
                        .map((channel, i) => (
                            <UserConversation
                                selectChannel={selectChannel}
                                handleSetSelectChannel={handleSetSelectChannel}
                                key={i}
                                channel={channel}
                            />
                        ))
                }
                {!searchChannel &&
                    channels
                        .sort((a, b) => b.fecha - a.fecha)
                        .map((channel, i) => (
                            <UserConversation
                                selectChannel={selectChannel}
                                handleSetSelectChannel={handleSetSelectChannel}
                                key={i}
                                channel={channel}
                            />
                        ))
                }
            </List>
        </Container>
    );
};

export default BodyAside;

const Container = styled.div`
    max-height: 80%;
    /* border-right: 0.1rem solid #ebebeb; */
    border-right: 0.5px solid #ebebeb;
`;

const List = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);

    @media (max-width: 768px) {
        visibility: ${(props) => (props.aside ? 'visible' : 'hidden')};
        opacity: ${(props) => (props.aside ? 1 : 0)};
        transition: all 0.5s ease;
        position: relative;
        top: 0;
        left: ${(props) => (props.aside ? '0' : '-100%')};
        bottom: 0;
        width: 100%;
        height: 100%;
    }
`;
