import React from 'react';
import styled from 'styled-components';

import DataUser from './DataUser';

import SimpleLoading from '../../../common/SimpleLoading';
import MenuPopover from './MenuPopover';

const HeaderBody = (props) => {
    const { statusUser, changeStatusChat, statusChat } = props;

    if (!statusUser) {
        return <SimpleLoading />;
    }

    return (
        <HeaderContent>
            <DataUser statusChat={statusChat} statusUser={statusUser} />
            <Button>
                <MenuPopover
                    statusChat={statusChat}
                    changeStatusChat={changeStatusChat}
                />
            </Button>
        </HeaderContent>
    );
};

export default HeaderBody;

const HeaderContent = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
`;

const Button = styled.div`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
        background-color: #ededed;
    }
`;
