import React from 'react';
import styled from 'styled-components';
import CustomBreadcrumbs from '../../components/common/CustomBreadcrumbs';
import MainChat from '../../components/templates/chat/MainChat';
import { titleHTML } from '../../helpers/title.helper';

const Chat = () => {
    titleHTML("Chat");
    
    return (
        <ChatContainer>
            <HeaderNav>
                <CustomBreadcrumbs />
            </HeaderNav>
            <MainChat />
        </ChatContainer>
    );
};

export default Chat;

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const HeaderNav = styled.div`
    width: 100%;
    height: 50px;
`;
