import axios from 'axios';

export async function getPublicProfile(id) {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_SISAPI}/v1/acropolis/users/public-profile/${id}`
        );
        return response.data.response_data;
    } catch (error) {
        return { error };
    }
}
