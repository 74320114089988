/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FilterStudyPlan from '../ui/molecules/studyPlan/FilterStudyPlan';
import SubjectsCard from '../common/cards/SubjectsCard';
import NoResultsFound from './NoResultsFound';
import SimpleLoading from './SimpleLoading';
import DefaultSearch from '../../modules/acropolisCommon/components/DefaultSearch';
import useDocumentState from '../../hooks/students/useDocumentState';
import useStudentStatus from '../../pages/campus/hooks/useStundetStatus';

const GridStudyPlan = (props) => {
    const {
        course,
        setDataAssigment,
        dataAssigment,
        setIsChange,
        isChange,
        dataProgram,
        isDiplomat,
        setIsFlag,
        getProgramStudyPlan,
        isFlag,
        programState,
        handleEnrollment,
        setData,
        setDataProgram,
        onInscription,
    } = props;

    // STATES
    const [values, setValues] = useState(course);
    const [currentState, setCurrentState] = useState('Todos');
    const [search, setSearch] = useState('');

    const { titulationState } = useDocumentState();
    const { statusStudentBlock } = useStudentStatus();

    // //EFFECT
    useEffect(() => {
        setValues(course);
    }, [course]);

    useEffect(() => {
        setValues(
            course.filter((subjects) => {
                return subjects?.name
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase());
            })
        );
        setCurrentState('Todos');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (currentState === 'Todos') {
            setValues(course);
        }
        if (currentState === 'En Curso') {
            setValues(course.filter((value) => value.state === 'confirmado'));
        }
        if (currentState === 'Egresado') {
            setValues(course.filter((value) => value.state === 'egresado'));
        }
        if (currentState === 'Pendiente') {
            setValues(course.filter((value) => value.state === 'pendiente'));
        }
    }, [currentState]);

    useEffect(() => {
        setTimeout(() => {
            setIsChange(false);
        }, 300);
    }, [dataAssigment]);

    // FUNCTION
    function handleCurrentState(newValue) {
        setCurrentState(newValue);
    }
    function handleChange(e) {
        setSearch(e.target.value);
    }

    return (
        <Container>
            <Header>
                <FilterWrapper>
                    <DefaultSearch
                        search={search}
                        handleChange={handleChange}
                        label="Buscar asignatura..."
                    />
                    <FilterStudyPlan
                        currentState={currentState}
                        handleCurrentState={handleCurrentState}
                    />
                </FilterWrapper>
            </Header>
            <SubjectsWrapper isChange={isChange} value={values?.length}>
                {values.length === 0 ? (
                    <>
                        <NoResultWrapper>
                            <NoResultsFound />
                        </NoResultWrapper>
                    </>
                ) : (
                    <>
                        {isChange ? (
                            <>
                                <SimpleLoading />
                            </>
                        ) : (
                            <>
                                {values.map((courses, index) => (
                                    <SubjectsCard
                                        key={index}
                                        data={courses}
                                        statusStudentBlock={statusStudentBlock}
                                        dataProgram={dataProgram}
                                        setDataProgram={setDataProgram}
                                        setData={setData}
                                        dataProgramName={dataProgram?.name}
                                        onInscription={onInscription}
                                        setIsFlag={setIsFlag}
                                        isFlag={isFlag}
                                        setDataAssigment={setDataAssigment}
                                        dataAssigment={dataAssigment}
                                        setIsChange={setIsChange}
                                        isDiplomat={isDiplomat}
                                        programState={programState}
                                        handleEnrollment={handleEnrollment}
                                        getProgramStudyPlan={
                                            getProgramStudyPlan
                                        }
                                        titulationState={titulationState}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </SubjectsWrapper>
        </Container>
    );
};

export default GridStudyPlan;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SubjectsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(
        auto-fill,
        minmax(
            ${(p) => (p.isChange ? '100%' : p.value > 0 ? '320px' : '100%')},
            1fr
        )
    );
    grid-gap: 2rem;

    @media (max-width: 425px) {
        display: flex;
        flex-direction: column;
    }
`;

const NoResultWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 0 auto;
`;

const FilterWrapper = styled.div`
    display: grid;
    gap: 12px;
`;
