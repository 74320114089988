import axios from "axios";

export const postDeleteNote = async (id) => {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/delete_resource_note/${id}`;

    try {
        const result = await axios.post(URL);
        return result.data?.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
