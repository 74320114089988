import { TextField } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

// import DataUser from "./DataUser";
import SendIcon from '@mui/icons-material/Send';

const FooterBody = (props) => {
    const { statusUser, createMessage, createMessageClick, statusChat } = props;
    //State Component

    return (
        <Container>
            <TextField
                id="msj"
                className="mb-1"
                style={{ margin: 8 }}
                placeholder="Escribe tu mensaje aquí"
                fullWidth
                margin="normal"
                InputLabelProps={{
                    shrink: true
                }}
                disabled={
                    statusChat && statusChat !== undefined
                        ? !statusChat.estado.estado
                        : false
                }
                onKeyDown={(e) => createMessage(e)}
            />
            <IconMessage
                onClick={() => {
                    createMessageClick(document.getElementById('msj').value);
                    document.getElementById('msj').value = '';
                }}
            >
                <SendIcon htmlColor="#B31D15" />
            </IconMessage>
        </Container>
    );
};

export default FooterBody;

const Container = styled.div`
    height: 140px;
    width: calc(100% - 2rem);
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
`;

const IconMessage = styled.div`
    /* background-color: blue; */
    /* border-bottom: .1px solid #c9c9c9; */
    width: 5%;
    display: flex;
    justify-content: center;
`;
