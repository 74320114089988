import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { achievementsActions } from '../../../../redux/actions';
import SimpleLoading from '../../../common/SimpleLoading';
import AchievementCard from '../my-progress/AchievementCard';

const Achievements = () => {
    // REDUX
    const dispatch = useDispatch();
    const achievements = useSelector(
        (state) => state.achievements.achievements
    );
    const user = useSelector((state) => state.auth.user);
    const listLoading = useSelector((state) => state.achievements.listLoading);

    // EFFECTS
    useEffect(() => {
        if (achievements === null) {
            dispatch(achievementsActions.getAchievementsRequest(user.sis_id));
        }
    }, []);

    // RETURN
    if (achievements === null || listLoading) {
        return <SimpleLoading padding="4rem" />;
    }

    return (
        <AchievementsContainer>
            {achievements.map((achievement) => (
                <AchievementCard value={achievement} key={achievement.id} />
            ))}
        </AchievementsContainer>
    );
};

export default Achievements;

const AchievementsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem;
`;
