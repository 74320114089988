import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import * as encode from "nodejs-base64-encode";

const CardElectives = (props) => {
    const { elective, handleClick } = props;

    const { publication_name, progress, type, id } = elective;

    const hash = encode.encode(id + "", "base64");

    return (
        <Container>
            <NameContainer>
                <IconContainer>
                    {type === "Bloque Contenido IA" ? (
                        <Icon icon="tabler:input-ai" />
                    ) : type === "Bloque Presencial" ? (
                        <Icon icon="bx:been-here" />
                    ) : (
                        <Icon icon="tdesign:gesture-click" />
                    )}
                </IconContainer>
                <p>{publication_name}</p>
            </NameContainer>
            <ProgressContainer>
                <Progress value={progress} />
                <span>{progress}%</span>
            </ProgressContainer>
            <ButtonContainer>
                <CustomButton
                    onClick={() => handleClick(hash)}
                    fullWidth
                    color="primary"
                    variant="contained"
                >
                    Cursar
                </CustomButton>
            </ButtonContainer>
        </Container>
    );
};

export default CardElectives;

const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 2rem;
    height: 75px;
    border-radius: 20px;
    border: solid 1px #dcdcdc;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
`;
const NameContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 350px;

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #222;
        font-size: 1rem;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
`;
const ProgressContainer = styled.div`
    display: flex;
    width: 360px;
    gap: 0.3rem;
    align-items: center;
    justify-content: flex-start;

    span {
        color: #222;
        font-size: 1rem;
    }
`;
const Progress = styled.div`
    height: 7px;
    width: 350px;
    background-color: #eaeaea;
    border-radius: 8px;
    position: relative;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: ${(p) => `${p.value}%`};
        border-radius: 8px;
        background-color: #35d0a5;
    }
`;

const ButtonContainer = styled.div`
    min-width: 200px;
    max-width: 200px;
`;

const CustomButton = styled(Button)`
    border-radius: 10px;
`;

const IconContainer = styled.div`
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 50%;
    background-color: #b31d15;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #fff;
        font-size: 1.3rem;
    }
`;
