import styled from "styled-components";
import useStatus from "../../../../../../hooks/common/useStatus";
import CardHeader from "./CardHeader";
import { Icon } from "@iconify/react/dist/iconify.js";
import CardSubmissionState from "./CardSubmissionState";

const PresentationHeader = (props) => {

    const { presentation, lastPresentation, nombre_publicacion } = props;

    const { setStrongStateColor, setLightStateColor, setStateText } = useStatus();

    return (
        <Header>
            <HeaderRender bg="#b31d15" textColor="#fff">
                {nombre_publicacion}
            </HeaderRender>
            <HeaderSectionContainer>
                <LeftContent>
                    <CardHeader
                        Icon={<Icon icon="ph:calendar-x-light" width="20px" height="1.2em" />}
                        title="Fecha limite"
                        subtitle={presentation?.fecha_max_entrega !== false ? new Date(presentation?.fecha_max_entrega)?.toLocaleDateString() : '-'}
                    />
                    <CardHeader
                        Icon={<Icon icon="grommet-icons:rotate-right" width="20px" height="1.2em" />}
                        title="Intentos"
                        subtitle={presentation?.cantidad_intentos === 100 ? 'Ilimitados' : presentation?.cantidad_intentos}
                    />
                    <CardHeader
                        Icon={<Icon icon="radix-icons:timer" width="20px" height="1.2em" />}
                        title="Tiempo"
                        subtitle={presentation?.tiempo || "-"}
                    />
                </LeftContent>
                <RightContent>
                    <CardSubmissionState
                        Icon={<Icon icon="lucide:file-pen-line" width="1.2em" height="1.2em" />}
                        strongColor={setStrongStateColor(lastPresentation?.redo ? 'rehacer' : lastPresentation?.state)}
                        lightColor={setLightStateColor(lastPresentation?.redo ? 'rehacer' : lastPresentation?.state)}
                        state={setStateText(lastPresentation?.redo ? 'rehacer' : lastPresentation?.state)}
                    />
                </RightContent>
            </HeaderSectionContainer>
        </Header>
    )
}

export default PresentationHeader;

const Header = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;

    @media (max-width: 100px) {
        display: flex;
        flex-direction: column;
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const HeaderRender = styled.div`
    width: 100%;
    border-radius: 30px 30px 0 0;
    height: 40px;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(p) => p.bg};
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: ${(p) => (p.textColor ? p.textColor : '#000')};
`;

const HeaderSectionContainer = styled.div`
    height: auto;
    width: calc(100% - 4rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;

    @media (width < 1400px) {
        flex-direction: column;
        gap: 1rem;
    }
`

const LeftContent = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (width < 1400px) {
        width: 100%;
        flex-direction: column;
    }
`;

const RightContent = styled.div`
    display: flex;
    align-items: center;

    @media (width < 1400px) {
        width: 100%;
    }
`;

