import React, { useEffect, useState } from "react";
import TabsComponent from "../../../components/common/TabsComponent";
import styled from "styled-components";
// import { Card } from "@mui/material";
import SimpleLoading from "../../../components/common/SimpleLoading";
import SubjectCalifications from "./SubjectCalifications";
import SubjectInstructors from "./SubjectInstructors";
import MyDeliveries from "./MyDeliveries";
import SubjectBoard from "./SubjectBoard";
import SubjectNotes from "./SubjectNotes";
import useTabHistory from "../../../hooks/useTabHistory";
import * as encode from "nodejs-base64-encode";
import SubjectAnnouncements from "../../../components/ui/organisms/subject/SubjectAnnouncements";

const SubjectTabs = (props) => {
    const { subject, idCourse, origin, programId, totalProgress, resetData } =
        props;

    const { handleTabHistory, initialTab } = useTabHistory();

    const mod = subject.courses;
    const descr = subject.information;

    const tabs = [
        { label: `Progreso asignatura`, id: 0 },
        { label: `Entregas`, id: 1 },
        { label: "Calificaciones", id: 2 },
        { label: "Apuntes", id: 3 },
        { label: "Cuerpo académico", id: 4 },
        { label: "Anuncios", id: 5 },
    ];

    const [tab, setTab] = useState(initialTab);
    const [valuesFake, setValuesFake] = useState([]);

    useEffect(() => {
        handleOnStudy();
    }, []);

    // FUNCTIONS
    const handleTab = (e, value) => {
        setTab(value);
        handleTabHistory(
            `${idCourse}?program=${encode.encode(
                programId,
                "base64"
            )}&origin=${origin}&tab=${value}`
        );
    };

    const handleOnStudy = () => {
        let arrayCourses = mod.sort((a, b) => a.order - b.order);

        for (let index = 0; index < arrayCourses.length; index++) {
            if (index === 0) {
                arrayCourses[index].onStudy = true;
            } else {
                if (
                    arrayCourses[index - 1].progress >= 100 ||
                    !!arrayCourses[index - 1]?.survey.done
                ) {
                    arrayCourses[index].onStudy = true;
                } else {
                    // COMENTADO PORQUE SEBA PIDIÓ QUE SE DESBLOQUEEN LOS BLOQUES EN LAS ASIGNATURAS PARA NO CAMBIAR TODA LA LÓGICA COMENTAMOS SOLO ESTA PARTE
                    // arrayCourses[index].onStudy = false;
                    arrayCourses[index].onStudy = true;
                }
            }
        }
        setValuesFake(arrayCourses);
    };

    function ToggleMain(props) {
        const { tab } = props;

        const cases = {
            0: (
                <SubjectBoard
                    subject={subject}
                    totalProgress={totalProgress}
                    origin={origin}
                    resetData={resetData}
                />
            ),
            1: (
                <>
                    <MyDeliveries />
                </>
            ),
            2: <SubjectCalifications idCourse={idCourse} />,
            3: <SubjectNotes />,
            4: <SubjectInstructors descr={descr} />,
            5: <SubjectAnnouncements id={subject?.id} />,
        };
        return cases[String(tab)] || <SubjectBoard />;
    }

    //RETURN
    if (subject.length === 0) {
        return (
            <CardFake>
                <SimpleLoading />
            </CardFake>
        );
    }

    return (
        <>
            <TabPosition>
                <TabsComponent tab={tab} handleTab={handleTab} tabs={tabs} />
            </TabPosition>
            <CardFake>
                <BodyWrapper>
                    <ToggleMain tab={tab} />
                </BodyWrapper>
            </CardFake>
        </>
    );
};
export default SubjectTabs;

const CardFake = styled.div`
    margin-top: 44px;

    @media (max-width: 768px) {
        margin-top: 70px;
    }
`;

const BodyWrapper = styled.div`
    padding: 0.5rem;
`;

const TabPosition = styled.div`
    position: absolute;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 2.5rem;
    left: -2rem;
    top: -32px;
    background-color: white;
    box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);

    @media (max-width: 1440px) {
        width: calc(100% + 4rem);
        left: -4rem;
    }

    @media (max-width: 768px) {
        width: calc(100% - 3rem);
        padding-left: 2.5rem;
        left: -1rem;
        top: 5px;
    }
`;
