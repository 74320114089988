import React from 'react';

import { Field } from 'formik';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

const DefaultRadio = (props) => {
    const { name, row, options } = props;

    // RETURN
    return (
        <Field as={RadioGroup} name={name} aria-label={name} row={row}>
            {options.map((option, index) => (
                <FormControlLabel
                    value={option.value}
                    key={index}
                    label={option.label}
                    control={<Radio size="small" color="primary" />}
                />
            ))}
        </Field>
    );
};

export default DefaultRadio;
