import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AsideChat from '../../ui/organisms/chat/AsideChat';
import BodyChat from '../../ui/organisms/chat/BodyChat';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { pushNotificationService } from '../../../app/modules/Services/chat.services';
import { socket } from '../../../helpers/connectionSocket.helper';
import SimpleLoading from '../../common/SimpleLoading';

const MainChat = () => {
    // State Redux
    const hash = useSelector((state) => state.chat.channel);
    const participants = useSelector((state) => state.chat.participants);
    const user = useSelector((state) => state.auth.user);

    // State Component
    const [userChat, setUserChat] = useState(false);
    const [channelChat, setChannelChat] = useState(false);
    const [newMessage, setNewMessage] = useState([]);
    const [sendMessage, setSendMessage] = useState([]);
    const [historyMessages, setHistoryMessage] = useState([]);
    const [aside, setAside] = useState(true);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        socket.emit(
            'verSalas',
            { ...user, nombre: user.name, sala: 'all' },
            (res) => {
            }
        );
        socket.on('salasAbiertas', (res) => {
            // setChannelChat(res);
            sortChannel(res)
        });

        if (hash !== '') {
            setLoading(true)
            socket.on('salasAbiertas', (res) => {
                // setChannelChat(res);
                sortChannel(res)
            });
            socket.emit(
                'entrarChat',
                {
                    ...user,
                    nombre: user.name,
                    sala: hash,
                    participants,
                    statusChat: true
                },
                (res) => {
                    setUserChat(res);
                    setLoading(false)
                }
            );
            socket.on('listaPersona', (res) => {
                setUserChat(res);
            });
            socket.on('crearMensaje', (res) => {
                sortChannel(channelChat)
                setNewMessage(res.mensaje);
            });
            socket.on('listaMensajes', (res) => {
                sortChannel(channelChat)
                setHistoryMessage(res);
            });
        }

        const disconnectUser = () => {
            socket.emit(
                'salirChat',
                { ...user, nombre: user.name },
                (data) => {
                    sortChannel(channelChat)
                }
            );
        };

        window.addEventListener('beforeunload', disconnectUser);

        return () => {
            window.removeEventListener('beforeunload', disconnectUser);
            socket.emit(
                'salirChat',
                { ...user, nombre: user.name },
                (data) => {
                    sortChannel(channelChat)
                }
            );
        };
    }, [hash]);

    const createMessageClick = (message) => {
        if (message !== '') {
            checkedNotification(userChat, user.partner_id, message);
            const data = {
                partner_id: user.partner_id,
                nombre: user.name,
                mensaje: message,
                sala: hash
            };
            socket.emit('crearMensaje', data);
            setSendMessage({ ...data, fecha: new Date().getTime() });
            message = '';
        }
    };
    // Functions
    const createMessage = (e) => {
        if (e.keyCode === 13) {
            if (e.target.value !== '') {
                checkedNotification(userChat, user.partner_id, e.target.value);
                const data = {
                    partner_id: user.partner_id,
                    nombre: user.name,
                    mensaje: e.target.value,
                    sala: hash
                };
                socket.emit('crearMensaje', data);
                setSendMessage({ ...data, fecha: new Date().getTime() });
                e.target.value = '';
                sortChannel(channelChat)
            }
        }
    };

    const checkedNotification = (usersChat, iPartnerId, message) => {
        let getConnect = usersChat.usuarios.filter(
            (user) => user.partner_id !== iPartnerId
        );
        let getDisconnect = usersChat.desconectados.filter(
            (user) => user.partner_id !== iPartnerId
        );
        let getParticipants = usersChat.participantes.filter(
            (user) => user.partner_id !== iPartnerId
        );

        const data = {
            to: {
                id: user.partner_id,
                name: user.name,
                image: user.foto
            },
            from: getParticipants[0].partner_id,
            message: message
        };

        // console.log(data);

        const pushNotification = async (data) => {
            return await pushNotificationService(data);
        };

        if (getConnect.length === 0 && getDisconnect.length > 0) {
            pushNotification(data);
        } else if (
            getConnect.length === 0 &&
            getParticipants.length > 0 &&
            getDisconnect.length === 0
        ) {
            pushNotification(data);
        } else {
            return;
        }
    };

    const sortChannel = (channels) => {
        if (!!channels) {
            let result = channels.sort((a, b) => a.fecha < b.fecha);
            setChannelChat(result);
        }
    };

    const changeStatusChat = (status) => {
        socket.emit(
            'bloqueoCanal',
            { sala: hash, statusChat: status, partner_id: user.partner_id },
            (res) => {
                setUserChat((state) => ({
                    ...state,
                    estado: {
                        estado: res.estado,
                        responsableBloqueo: res.responsableBloqueo
                    }
                }));
            }
        );
    };

    if (channelChat === false) {
        return <SimpleLoading />;
    }

    return (
        <ChatContainer>
            <GridLayout aside={aside}>
                <AsideChat
                    channels={channelChat}
                    aside={aside}
                    setAside={setAside}
                />
                {hash !== '' ? (
                    <BodyChat
                        newMessage={newMessage}
                        createMessage={createMessage}
                        createMessageClick={createMessageClick}
                        sendMessage={sendMessage}
                        messages={historyMessages}
                        userLocal={user}
                        users={userChat}
                        channels={channelChat}
                        changeStatusChat={changeStatusChat}
                        aside={aside}
                        loading={loading}
                    />
                ) : (
                    <Directory>
                        <Link to="/networking">
                            <Button variant="contained" color="primary">
                                Inicia una nueva conversación
                            </Button>
                        </Link>
                    </Directory>
                )}
            </GridLayout>
        </ChatContainer>
    );
};

export default MainChat;

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 80vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
    border-radius: 0.3rem;
    row-gap: 1rem;
`;

const GridLayout = styled.div`
    display: grid;
    transition: 0.5s ease;
    /* grid-template-rows: 1fr; */
    height: 100%;
    /* overflow-y: hidden; */
    grid-template-columns: 1fr 3fr;

    @media (max-width: 768px) {
        grid-template-columns: ${(props) =>
        props.aside ? '2fr 0fr' : '20% 3fr'};
    }
`;

const Directory = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
