import React from "react";
import styled from "styled-components";
import { Text } from "../../components/common/Texts";
import { randomGreeting } from "../../helpers/randomGreeting";
// import Calendar from '../../components/templates/teacher/calendar/Calendar';
import CoursesTeacher from "../../components/templates/teacher/courses/Courses";
import Qualification from "../../components/templates/teacher/qualification/Qualification";
import MessagesTeacher from "../../components/templates/teacher/messages/MessagesTeacher";
import { useSelector } from "react-redux";
import { titleHTML } from "../../helpers/title.helper";
import Binnacle from "../../components/common/teacher-academic/Binnacle";

const Dashboard = () => {
    titleHTML("Dashboard");

    const { user } = useSelector((state) => state.auth);

    return (
        <DashboardWrapper>
            <Welcome>
                <Greetings>
                    <Text fontSize="1.5rem" fontWeight="bold">
                        {randomGreeting()} {user.name}
                    </Text>
                    <Text fontSize="1rem" fontWeight="bold" color="#777777">
                        ¡Que alegría verte de nuevo por aquí! Échale un vistazo
                        a tus pendientes y ten un excelente día.
                    </Text>
                </Greetings>
                <MessagesTeacher id={user.partner_id} />
            </Welcome>
            {/* <Qualification type="all" idTeacher={user.repo_id}/> */}
            <Binnacle />
            {/* <Calendar /> */}
            <CoursesTeacher />
        </DashboardWrapper>
    );
};

export default Dashboard;

const DashboardWrapper = styled.div`
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 420px auto;
    grid-template-areas:
        "messages actions actions"
        "courses  courses       courses      ";
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Welcome = styled.div`
    display: grid;
    grid-area: messages;
    grid-template-rows: auto 1fr;
    row-gap: 1rem;
`;

const Greetings = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
