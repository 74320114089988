import { useEffect, useState } from 'react';
import { getStudentsServices } from '../redux/api/students';
import { useSnackbar } from "react-simple-snackbar";
import { optionsStyle } from '../utils/snackStyles';

const useStudentsTeacher = (minify = false) => {
    const [students, setStudents] = useState(null);
    const [initialStudents, setInitialStudents] = useState(null);

    const [openSnackbar] = useSnackbar(optionsStyle);

    useEffect(() => {
        if (!students) getStudents();
    }, [students]);

    const getStudents = async () => {
        const request = await getStudentsServices(minify);
        if (request.error) {
            openSnackbar('¡No se pudieron cargar los estudiantes!');
            setStudents(false);
            return;
        }
        setStudents(request);
        setInitialStudents(request);
    };

    const handleStudents = (newStudents) => {
        setStudents(newStudents);
    };

    return { initialStudents, students, handleStudents };
};

export default useStudentsTeacher;
