import * as React from "react";

import Dialog from "@mui/material/Dialog";

import Slide from "@mui/material/Slide";
import styled from "styled-components";
import { Text } from "../../../common/Texts";
import SelectRol from "../../atoms/SelectRol";
import SchoolIcon from "@mui/icons-material/School";
import BallotIcon from "@mui/icons-material/Ballot";
import GroupIcon from "@mui/icons-material/Group";
import { CircularProgress, IconButton } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectRolModal(props) {
    const {
        open,
        handleLogin,
        loadingGetUser,
        rolOpen,
        handleClose = false,
        active = false,
    } = props;

    const handleRoles = (rol) => {
        // setOpen(false);
        const cases = {
            alumno: {
                color: "#d17c2c",
                name: "Alumno",
                icon: <SchoolIcon />,
            },
            docente: {
                color: "#5e80db",
                name: "Profesor",
                icon: <BallotIcon />,
            },
            academico: {
                color: "#1e8065",
                name: "Académico",
                icon: <GroupIcon />,
            },
            ondemand: {
                color: "#B31D15",
                name: "AcrópolisLab",
                icon: <img width="45px" src="./assets/aden/alab-white.svg" />,
            },
            visita: {
                color: "#302f36",
                name: "Visita",
                icon: <RemoveRedEyeIcon />,
            },
        };

        return cases[rol] || cases["visita"];
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                // onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Container id="1">
                    {!!handleClose && (
                        <Close onClick={handleClose}>
                            <CloseIcon color="primary" />
                        </Close>
                    )}
                    {!loadingGetUser ? (
                        <CardRol>
                            <Text
                                color="#333333"
                                fontWeight="600"
                                fontSize="2rem"
                            >
                                ¡Hola de nuevo!
                            </Text>
                            <Text
                                color="#777777"
                                fontWeight="500"
                                fontSize="1.5rem"
                            >
                                ¿Con qué rol deseas entrar hoy?
                            </Text>
                            <SelectWrapper>
                                {rolOpen.map((rol) => {
                                    const { color, name, icon } =
                                        handleRoles(rol);
                                    if (active === rol) {
                                        return (
                                            <SelectRol
                                                disabled={true}
                                                value={rol || "visita"}
                                                color={color}
                                                icon={icon}
                                                name={name}
                                            />
                                        );
                                    }
                                    return (
                                        <SelectRol
                                            value={rol || "visita"}
                                            handleLogin={handleLogin}
                                            color={color}
                                            icon={icon}
                                            name={name}
                                        />
                                    );
                                })}
                            </SelectWrapper>
                        </CardRol>
                    ) : (
                        <CardRol>
                            <CircularProgress />
                        </CardRol>
                    )}
                </Container>
            </Dialog>
        </div>
    );
}

const Container = styled.div`
    max-width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    background-color: #cccccc;
`;

const Close = styled(IconButton)`
    position: absolute;
    z-index: 99;
    top: 15px;
    right: 15px;
`;

const CardRol = styled.div`
    min-width: 800px;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 30px;
    gap: 2rem;
    @media (max-width: 768px) {
        min-width: auto;
        width: calc(100% - 30px);
        margin: 0 15px;
    }
`;

const SelectWrapper = styled.div`
    display: flex;
    gap: 1rem;
    margin: 0 1rem;
    @media (max-width: 768px) {
        width: 100%;
        margin: 2rem 0;
        flex-direction: column;
    }
`;
