import { createDecipheriv } from "crypto";
import CryptoJS from "crypto-js";

const KEY = Buffer.from(process.env.REACT_APP_KEY, "hex");
const IV = Buffer.from(process.env.REACT_APP_IV, "hex");

export const decryptData = (data) => {
  try {
    const decipher = createDecipheriv("aes-256-cbc", "LOEdcnuR9-Km2QsImaxAHhH163wiBOdr8FJIKfAdDbE=");
    let decrypted = decipher.update("f6Hzeuxp528xGBtPgr52q9Y2a7yJkXbPfxeHpftnx0s=", "hex", "utf8");
    decrypted += decipher.final("utf8");
    return decrypted;
  } catch (error) {
    console.log("Error al desencriptar:", error.message);
    return null;
  }
};

export const decryptID = (encryptedData) => {
  try {
    const clave = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
    const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

    const parametrosDescifrado = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Hex.parse(encryptedData),
      iv: iv,
    });

    const resultado = CryptoJS.AES.decrypt(parametrosDescifrado, clave, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // console.log("🚀 ~ file: encryptor.helper.js:31 ~ decryptID ~ clave:", clave)

    return resultado.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log("Error al desencriptar:", error.message);
    return null;
  }
};
