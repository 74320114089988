import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SimpleLoading from '../../components/common/SimpleLoading';
import MainSubject from './components/MainSubject';
import { titleHTML } from '../../helpers/title.helper';
import * as encode from 'nodejs-base64-encode';
import SkeletonSubject from '../course/SkeletonSubject';
import { useQueryParams } from '../../hooks/useQueryParams';
import useLayout from '../../hooks/useLayout';
import GenericError from '../../components/common/GenericError';

export default function CourseSubject() {
    titleHTML('Asignatura');

    const errors = [404, 500, 502];
    const { id } = useParams();
    const [data, setData] = useState([]);
    const repo_id = useSelector((state) => state.auth.user.repo_id);
    const params = useQueryParams();
    const origin = params.get('origin') || '';
    const program = params.get('program') || '';

    const hash = encode.decode(id, 'base64');
    const programId = encode.decode(program, 'base64');

    const { setMenu } = useLayout();

    const getData = async () => {
        const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/${hash}?repo_id=${repo_id}`;
        try {
            const res = await axios.get(URL);
            const data = res.data;
            const postData = data.response_data.assignment;
            setData(postData);
            setMenu(postData.name);
            const URL_POST = `${process.env.REACT_APP_REPO}/v1/repo_aden/enrollment/update_latest_entry_acropolis/${repo_id}?asignatura_id=${postData?.id}`;
            try {
                await axios.post(URL_POST);
            } catch (error) {
                return {
                    error,
                };
            }
        } catch (error) {
            setData(error.request.status);
            return {
                error,
            };
        }
    };

    useEffect(() => {
        if (data.length === 0) {
            getData();
        }
        // eslint-disable-next-line
    }, [data]);

    const resetData = () => {
        setData([]);
    };

    if (data.length === 0) {
        return <SkeletonSubject />;
    }

    if (errors.includes(data)) {
        return <GenericError reloadFn={resetData} />;
    }

    return (
        <CourseSubjectWrapper>
            {data.length !== 0 ? (
                <MainSubject
                    subjectSelected={data}
                    idCourse={id}
                    origin={origin}
                    programId={programId}
                    resetData={resetData}
                />
            ) : (
                <SimpleLoading />
            )}
        </CourseSubjectWrapper>
    );
}

const CourseSubjectWrapper = styled.div`
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
