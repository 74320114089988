import axios from 'axios';

// TRAER DATOS DEL CALENDARIO
export async function getMeetings() {
    const URL = `${window.location.origin}/mock/teacher.json`;
    try {
        const response = await axios.get(URL);
        return response.data.response_data.meetings;
    } catch (error) {
        return { error };
    }
}
// ACTUALIZAR DATOS DEL CALENDARIO
export async function upDateMeetings(response_data) {
    const URL = `${window.location.origin}/mock/teacher.json`;
    try {
        return axios.put(URL, response_data);
    } catch (error) {
        return { error };
    }
}

// TRAER ESTUDIANTES PARA LA TABLA
export async function getStudents(
    id,
    body,
    pagination = { offset: 0, size: 10 }
) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/students_activities/${id}?page_offset=${pagination.offset}&page_size=${pagination.size}`;
    try {
        const response = await axios.post(URL, body);
        const { data, headers } = response;
        return { data, headers };
    } catch (error) {
        return { error };
    }
}

//TRAER AVANCE DE LOS ESTUDIANTES POR ASIGNATURA
export async function getAdvanceByAssignment(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/assignment/student_progress/${id}`;
    try {
        const response = await axios.get(URL);
        return response;
    } catch (error) {
        return { error };
    }
}

// ACTUALIZAR DATOS DEL ESTUDIANTE
export function upDateStudents(response_data) {
    const URL = `${window.location.origin}/mock/teacher.json`;
    try {
        return axios.put(URL, response_data);
    } catch (error) {
        return { error };
    }
}

// TRAER ACTIVIDADES DE LA TABLA
export async function getActivities() {
    const URL = `${window.location.origin}/mock/teacher.json`;
    try {
        const response = await axios.get(URL);
        return response.data.response_data.activities;
    } catch (error) {
        return { error };
    }
}

// Trae las tareas de un profe en una asignatura
export async function getTaskPendingByCourse(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/pending_by_assignment/${id}?elective_activities=true`;
    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        if (error.response.status === 404) {
            return [];
        }
        return { error };
    }
}

// Trae las tareas de un profe
export async function getTaskPending(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/pending_by_professor/${id}`;
    // const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/pending_correction/50`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        if (error.response.status === 404) {
            return [];
        }
        return { error };
    }
}

// Trae las tareas de un profe
export async function getSubmissionById(idSub, idAs) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/submission/${idSub}?asignatura_id=${idAs}`;

    try {
        const response = await axios.get(URL);
        return response.data.response_data;
    } catch (error) {
        if (error.response.status === 404) {
            return [];
        }
        return { error };
    }
}

// Corregir tarea
export async function setTaskByStudent(body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/correct`;

    try {
        const response = await axios.post(URL, body);
        return response.data;
    } catch (error) {
        return { error };
    }
}

// Editar tarea
export async function setTaskByStudentEdit(body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/activities/correct/update`;

    try {
        const response = await axios.put(URL, body);
        return response.data;
    } catch (error) {
        return { error };
    }
}

// Trae info adicional del perfil del profesor
export async function getAdditionalData(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty`;

    try {
        const type_document = await axios.get(URL + '/tipo_documento_unico');
        const cv = await axios.get(URL + '/cv/' + id);
        return { type_document: type_document.data, cv: cv.data };
    } catch (error) {
        return { error };
    }
}

// Carga un documento del perfil del profesor
export async function postDocumentService(id, body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/subir_documento/${id}`;

    try {
        const response = await axios.post(URL, body);
        return response.data;
    } catch (error) {
        return { error };
    }
}

export async function getTypesDocumentsService(id) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/tipo_documentacion`;

    try {
        const response = await axios.get(URL);
        return response.data;
    } catch (error) {
        return { error };
    }
}

export async function getCalificationsService() {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/maxima_calificacion`;

    try {
        const response = await axios.get(URL);
        return response.data;
    } catch (error) {
        return { error };
    }
}

// Carga perfil del profesor
export async function postProfileService(id, body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/faculty/modificar_datos/${id}`;

    try {
        const response = await axios.post(URL, body);
        return response.data;
    } catch (error) {
        return { error };
    }
}

// Traer cursos canvas del profesor
export async function getCoursesCanvas(id) {
    const URL = `${process.env.REACT_APP_SISAPI}/v1/acropolis/faculty/assignments/${id}`;

    try {
        const response = await axios.get(URL);
        return response.data;
    } catch (error) {
        return { error };
    }
}

// adjunta archivos en la correción del profesor
export async function postAttachmentsFiles(body) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/resources/correct_submission`;

    try {
        const response = await axios.post(URL, body);
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}
