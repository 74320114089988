import React, { useEffect, useState } from 'react';
import Twemoji from 'react-emoji-render';
import styled from 'styled-components';

import './chat.css';

export const ChatMensaje = ({ color, user, msg, esEnviado = true, date }) => {
    const isUser = esEnviado ? true : false;
    const justify = esEnviado ? 'flex-end' : 'flex-start';

    const [stateDate, setStateDate] = useState(false);

    useEffect(() => {
        let date1 = new Date(date);
        let dateNow =date1.getDate() + '/' + (date1.getMonth()+1) +'/'+ date1.getFullYear() + ' | ' +  date1.getHours() + ':' + date1.getMinutes();
        setStateDate(dateNow);
    }, []);

    return (
        <MessageWrapper>
            <PositionMsg jc={justify}>
                <Message user={isUser}>
                    {typeof msg === 'string' ? (
                        <p>
                            <Twemoji text={msg} />
                        </p>
                    ) : (
                        <p>{msg}</p>
                    )}
                    <Hour>{stateDate ? stateDate : '...'}</Hour>
                </Message>
            </PositionMsg>
        </MessageWrapper>
    );
};

const MessageWrapper = styled.div`
    width: 100%;
    height: auto;
`;
const PositionMsg = styled.div`
    display: flex;
    justify-content: ${(p) => p.jc};
`;
const Message = styled.div`
    position: relative;
    padding: 0.5rem 2rem 0.5rem 1rem;
    background-color: ${(p) => (p.user ? '#f5f3f2' : '#f5deda')};
    margin: 0.1rem 0;
    border-radius: 0.3rem;
    color: #535353;
    p{
        margin: 0.5rem 0.25rem;
    }
`;
const Hour = styled.div`
    position: absolute;
    bottom: 3px;
    right: 3px;
    font-size: 0.5rem;
    color: #888888;
    white-space: nowrap;
`;
