import { useState, useEffect } from 'react';
import { Pagination } from '@mui/material';
import styled from 'styled-components';

const GenericPagination = (props) => {
    const { handleCurrentPage, totalItems, pageSize, currentPage, loading } = props;

    return (
        <PaginationWrapper>
            <Pagination
                count={Math.ceil(totalItems / pageSize)}
                page={currentPage}
                showFirstButton
                showLastButton
                onChange={handleCurrentPage}
                disabled={loading}
            />
        </PaginationWrapper>
    );
};

export default GenericPagination;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
