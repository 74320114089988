import React, { useState } from 'react';
import styled from 'styled-components';

import { Button } from '@mui/material';
import { EditRounded } from '@mui/icons-material';
import ModalEditProfile from '../../../templates/profile/ModalEditProfile';
import SaveSnackbar from '../../../templates/profile/SaveSnackbar';

const EditProfile = () => {
    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleOpenModal() {
        setIsOpen(true);
    }

    function handleCloseModal() {
        setIsOpen(false);
    }

    return (
        <>
            <ButtonWrapper>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<EditRounded />}
                    onClick={handleOpenModal}
                >
                    Editar perfil
                </Button>
            </ButtonWrapper>
            <ModalEditProfile isOpen={isOpen} closeModal={handleCloseModal} />
            <SaveSnackbar />
        </>
    );
};

export default EditProfile;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: max-content;
    width: 200px;

    button {
        width: 100%;
    }
`;
