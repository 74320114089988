import React, { useState } from 'react';
import styled from 'styled-components';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import OptionModalEditProfile from './OptionModalEditProfile';
import TabsComponent from '../../../common/TabsComponent';
// import { profileSchema } from "../../../../utils/schemas"; Agregar cuando se haya finalizado el completado de perfil
import { authActions } from '../../../../redux/actions';

const tabs = [
    { label: 'Información personal', id: 0 },
    { label: 'Información de contacto', id: 1 },
    { label: 'Datos de la cuenta', id: 2 },
    { label: 'Formación y experiencia', id: 3 },
    { label: 'Intereses y motivaciones', id: 4 }
];

const BodyModalEditProfile = () => {
    // REDUX
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const loading = useSelector((state) => state.auth.loading);

    // STATE
    const initialValues = {
        // OptionOne
        name: user.name,
        biography: user.biography,
        document_type: user.document_type,
        document_number: user.document_number,
        birthday: user.birthday,
        nationality: user.nationality,
        country: user.country,
        city: user.city,
        gender: user.gender,
        profile_is_public: user.profile_is_public,
        zip: user.zip,
        street: user.street,
        // OptionTwo
        personal_email: user.personal_email,
        phone: user.phone,
        mobile: user.mobile,
        whatsapp: user.whatsapp,
        facebook: user.facebook,
        instagram: user.instagram,
        twitter: user.twitter,
        skype: user.skype,
        linkedin: user.linkedin,
        // Option three
        new_password_modal: "",
        confirm_new_password: "",
        // OptionFour
        profession: user.profession,
        company: user.company,
        current_job: user.current_job,
        job_area: user.job_area,
        work_email: user.work_email,
        work_phone: user.work_phone,
        work_experience: user.work_experience,
        academic_training: user.academic_training,
        // OptionFive
        motivaciones: user.motivaciones,
        intereses: user.intereses
    };

    // STATE
    const [currentTab, setCurrentTab] = useState(0);

    // FUNCTIONS
    function changeTab(e, value) {
        setCurrentTab(value);
    }

    function handleSubmit(values) {
        dispatch(
            authActions.updateUserRequest({
                ...values,
                phone: values.phone.length > 4 ? values.phone : '',
                mobile: values.mobile.length > 4 ? values.mobile : ''
            })
        );
    }

    // RETURN
    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
                <Container>
                    {/** Seleccionar opcion */}
                    <TabsComponent
                        tab={currentTab}
                        handleTab={changeTab}
                        tabs={tabs}
                    />
                    {/** Opciones */}
                    <OptionModalEditProfile
                        currentTab={currentTab}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                    {/** Boton submit */}
                    <ButtonWrapper>
                        {loading ? (
                            <ProgressWrapper>
                                <CircularProgress color="primary" size={32} />
                            </ProgressWrapper>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                            >
                                Guardar cambios
                            </Button>
                        )}
                    </ButtonWrapper>
                </Container>
            )}
        </Formik>
    );
};

export default BodyModalEditProfile;

const Container = styled(Form)`
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem;
    height: 100%;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-left: auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const ProgressWrapper = styled.div`
    padding: 0.25rem 6rem;
`;
