import React, { useState } from "react";
import styled from "styled-components";

// Redux
import { getDataEvaluationService } from "../../../../../redux/api/courses";
import { useSelector } from "react-redux";

// Assets
import Confetti from "../../../../../assets/media/gif/confetti.gif";
import { Icon } from "@iconify/react/dist/iconify.js";
import CommonButton from "../../../../../components/common/CommonButton";
import ModalCorrectAnswers from "./modals/ModalCorrectAnswers";

const PageCalification = (props) => {
    const {
        qualification,
        evaluation,
        handleNewTry,
        handleReview,
        objId,
        resourceId,
        idEvaluacion,
        setSubmission,
        handleSimpleReview
    } = props;

    const user = useSelector((state) => state?.auth?.user);

    const [openModal, setOpenModal] = useState(false);

    const hasApproved = (qualification?.nota / evaluation?.puntaje_evaluacion) * 100 >= 80;

    const handlePromp = () => {
        setOpenModal(true)
    };

    const handleView = () => {
        getDataPresentation({
            id: idEvaluacion,
            user: user.repo_id,
            obj: objId,
            reso: resourceId,
        });
    };

    const getDataPresentation = async (data) => {
        setSubmission(null)
        const response = await getDataEvaluationService(data);
        if (!response) {
            setSubmission(false);
        } else {
            setSubmission(
                response.submissions[response.submissions.length - 1]
            );
        }
    };

    return <>
        <CalificationWrapper>
            <section>
                <h1>
                    {evaluation?.name}
                </h1>
            </section>
            {
                hasApproved && <BgGif src={Confetti} />
            }
            <Body>
                <LogoCheck hasApproved={hasApproved}>
                    {
                        hasApproved
                            ? <Icon icon="simple-line-icons:check" width="42px" height="42px" />
                            : <Icon icon="simple-line-icons:close" width="42px" height="42px" />
                    }
                </LogoCheck>
                <Title>
                    {
                        hasApproved ?
                            <>
                                <b>¡Felicidades!</b>
                                <br />
                                Aprobaste el examen
                            </>
                            :
                            <>
                                Lamentamos informarte que <b> no has alcanzado la
                                    <br />
                                    puntuación necesaria </b> para aprobar este examen.
                            </>
                    }
                </Title>
                <Subtitle>Tu calificación:</Subtitle>
                <Number>
                    <b>{qualification?.nota}</b> / {qualification?.total_puntaje}
                </Number>
                <Footer>
                    <WrapperUpButtons>
                        <CommonButton
                            label={"Volver a realizar el examen"}
                            Icon={() => <Icon icon="pajamas:retry" width="24px" height="24px" />}
                            onClick={handleNewTry}
                            disabled={
                                evaluation?.cantidad_intentos - 1 > 0
                                    ? false
                                    : true
                            }
                            variant="filled"
                        />
                    </WrapperUpButtons>
                    <WrapperBottomButtons>
                        <CommonButton
                            label={"Ver mis respuestas"}
                            onClick={() => {
                                handleView()
                                handleSimpleReview(2)
                            }}
                            variant="outlined"
                        />
                        <CommonButton
                            label={"Ver respuestas correctas"}
                            onClick={handlePromp}
                            variant="outlined"
                        />
                    </WrapperBottomButtons>
                </Footer>
            </Body>
        </CalificationWrapper>
        <ModalCorrectAnswers
            isOpen={openModal}
            handleCloseModal={() => setOpenModal(false)}
            handleClick={() => {
                handleReview()
                setOpenModal(false)
            }}
        />
    </>;
};

export default PageCalification;

const CalificationWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 4rem;
    position: relative;
    border-radius: 30px;
    padding-bottom: 4rem;
    box-shadow: 0px 3px 6px 0px #0000003B, 0px 3px 6px 0px #00000029;
    border: 1px solid #A8A8A8;
    background-color: #FFF;


    section {
        width: 100%;
        height: 54px;
        background-color: #B31D15;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px 30px 0 0;

        h1 {
            color: #FFF;
        }
    }
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const LogoCheck = styled.div`
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: ${(props) => props.hasApproved ? "#3BD39C" : "#E94C44"};
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;
const Title = styled.h2`
    font-size: 20px;
    font-weight: 500;
    margin: 3rem 0;
    z-index: 2;
    color: #222;
    text-align: center;
`;

const Subtitle = styled.h6`
    font-size: 14px;
    font-weight: 500;
    color: #616161;
    z-index: 2;
`;

const Number = styled.h2`
    font-size: 24px;
    font-weight: 700;
    z-index: 2;
    color: #616161;
    margin-bottom: 3rem;

    b {
        color: #222;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: auto;
    z-index: 2;
`;

const WrapperBottomButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
`;

const WrapperUpButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const BgGif = styled.img`
    height: calc(100% - 54px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    filter: opacity(60%);
    border-radius: 30px;
    pointer-events: none;
`;
