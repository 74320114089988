import React, { useState } from 'react';
import styled from 'styled-components';
import Commentary from './Commentary';
import { Icon } from '@iconify/react/dist/iconify.js';

const InputFile = (props) => {
    const { setCommentary, file, setFile, fileError, setFileError } = props;

    const [infoFile, setInfoFile] = useState(null);
    const [sizeFile, setSizeFile] = useState(false);
    const [dragOver, setDragOver] = useState(false);

    const handleChange = (e) => {
        if (file === null) {
            handleFile(e.target.files[0]);
        }
    };

    const allowedTypes = [
        'pdf',
        'doc',
        'docx',
        'rtf',
        'txt',
        'zip',
        'rar',
        'plain',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
        'avi',
        'mp4',
        'mpg',
        'mkv',
        'gif',
        'jpeg',
        'jpg',
        'png',
    ];

    const handleFile = (selectedFile) => {
        setFileError(false);
        setSizeFile(false);
        const type = selectedFile.type.split('/')[1];
        const lastDotIndex = selectedFile.name.lastIndexOf('.');
        const typeName =
            lastDotIndex !== -1
                ? selectedFile.name.substring(lastDotIndex + 1)
                : '';


        if (!allowedTypes.includes(type) && !allowedTypes.includes(typeName)) {
            setFileError(true);
        } else {
            setFileError(false);
        }
        const size = (selectedFile.size / 1048576).toFixed(2);
        if (size <= 15) {
            setFile(selectedFile);
            setInfoFile({
                name: selectedFile.name,
                size: size + ' MB',
                type: type,
            });
        } else {
            setSizeFile(true);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const droppedFile = e.dataTransfer.files[0];
        handleFile(droppedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    return (
        <Wrapper>
            <Container>
                <Label
                    htmlFor="fileInput"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    dragOver={dragOver}
                >
                    {!fileError ? (
                        !sizeFile ? (
                            file === null ? (
                                <>
                                    <Icon
                                        icon="ic:sharp-cloud-upload"
                                        width="1.2em"
                                        height="1.2em"
                                    />
                                    <Gap>
                                        <Span>
                                            Arrastre y suelte el archivo aquí
                                        </Span>
                                        <Span>o</Span>
                                        <ButtonFile>
                                            Selecciona un archivo
                                        </ButtonFile>
                                    </Gap>
                                </>
                            ) : (
                                <>
                                    <InfoFileDiv>
                                        <span>
                                            <TitleFile>
                                                {infoFile?.name}
                                            </TitleFile>
                                            <Size>{infoFile?.size}</Size>
                                        </span>
                                        <button onClick={() => setFile(null)}>
                                            <Icon
                                                icon="mdi:trash"
                                                width="24px"
                                                height="24px"
                                            />
                                        </button>
                                    </InfoFileDiv>
                                    <UploadSuccess>
                                        <Icon
                                            icon="simple-line-icons:check"
                                            width="24px"
                                            height="24px"
                                        />
                                        <p>
                                            El archivo se subió correctamente.{' '}
                                            <b>
                                                No olvide enviar su actividad.
                                            </b>
                                        </p>
                                    </UploadSuccess>
                                </>
                            )
                        ) : (
                            <InfoFileDiv>
                                <TitleFile>Peso máximo permitido</TitleFile>
                                <Size>15MB</Size>
                                <button onClick={() => {
                                    setFile(null)
                                    setFileError(false)
                                    setSizeFile(false)
                                }}>
                                    <Icon
                                        icon="mdi:trash"
                                        width="24px"
                                        height="24px"
                                    />
                                </button>
                            </InfoFileDiv>
                        )
                    ) : (
                        <InfoFileDiv>
                            <TitleFile>
                                Solo se permiten los siguientes formatos
                            </TitleFile>
                            <Size>
                                {
                                    allowedTypes.map((type, index) => `${type}, `)
                                }
                            </Size>
                            <button
                                onClick={() => {
                                    setFile(null);
                                    setFileError(false)
                                    setSizeFile(false)
                                }}
                            >
                                <Icon
                                    icon="mdi:trash"
                                    width="24px"
                                    height="24px"
                                />
                            </button>
                        </InfoFileDiv>
                    )}
                </Label>
                <Input
                    multiple={false}
                    onChange={handleChange}
                    id="fileInput"
                    type="file"
                />
                <Commentary setCommentary={setCommentary} />
            </Container>
        </Wrapper>
    );
};

export default InputFile;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        gap: 19rem;
        width: 100%;
    }
`;

const Container = styled.div`
    box-sizing: border-box;
    height: 90%;
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
        margin-bottom: 1.5rem;
        flex-direction: column;
    }
`;

const Label = styled.label`
    width: calc(50% - 4rem);
    height: calc(100% - 4px);
    min-height: calc(400px - 4rem);
    outline: 2px dashed #999999;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f1f1f1;
    transition: 0.3s ease-in-out all;
    padding: 2rem;
    svg {
        color: #a8a8a8;
        font-size: 4rem;
    }
    background-color: ${({ dragOver }) => (dragOver ? '#e0e0e0' : '#f1f1f1')};
    :hover {
        background-color: #f0f0f0;
    }

    @media (max-width: 768px) {
        width: calc(100% - 4rem);
        min-height: 300px;
    }
`;

const Input = styled.input`
    display: none;
`;

const Span = styled.span`
    font-weight: 700;
    font-size: 14px;
    color: #616161;
`;

const ButtonFile = styled.div`
    cursor: pointer;
    text-align: center;
    padding: 12px 24px;
    border-radius: 100px;
    background-color: #cd2118;
    font-size: 15px;
    font-weight: 600;
    color: white;
`;

const UploadSuccess = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 14px;
    background-color: #fff;
    width: 90%;
    padding: 8px 1rem;
    border-radius: 16px;
    border: 1px solid #c4c4c4;
    color: #28a986;
    font-size: 12px;
    font-weight: 400;

    svg {
        color: #28a986;
    }
`;

const InfoFileDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 90%;
    padding: 8px 1rem;
    border-radius: 16px;

    span {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
    }

    button {
        cursor: pointer;
    }
`;

const TitleFile = styled.div`
    text-transform: uppercase;
    color: #000;
    font-weight: 700;
    font-size: 12px;
`;

const Size = styled.div`
    text-transform: uppercase;
    color: #a8a8a8;
    font-size: 10px;
`;

const Gap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;
